import { DropdownOption } from '../../../../engrator-core/ui';

export type SmartIntType = {
    id: string;
    name: string;
    internal: any;
}
export const SMART_INT_TYPE_CONTINUOUS_INTEGRATION_ID = 'ContinuousIntegration';

export function createGenericItemSmartIntType(): DropdownOption {
    return {
        value: 'GenericItem',
        label: 'Generic Item',
        object: ''
    };
}
