import React, {Component} from "react";

import {FieldError, UI} from 'src/engrator-core';
import {AlertChannel, createEmptyNewAlert, getAlertChannelName, NewAlert} from "./new-alert.type";
import {createAlert, fetchAvailableChannels, sendTestEmail} from "../rest-api";
import {DocumentationLink, DropdownOption} from "../../../../engrator-core/ui";
import {NotificationsWebhooksHeader} from "./notifications-webhooks-header";

type Props = {
    closeHandler: (refresh: boolean) => void;
};
type State = {
    error?: FieldError,
    testEmailError?: FieldError,
    testSuccess: boolean,
    alert: NewAlert,
    isSendingTestEmail: boolean;
    isLoading: boolean,
    showNotifications: boolean;
};

const NOTIFICATIONS_WEBHOOK_INITIAL_CONTENT = '{"failedSynces": "${failedSyncs}", "failedRuns": "${failedRuns}", "timestamp": "${timestamp}"}';

export class NotificationsCreatePage extends Component<Props, State> {
    private options: DropdownOption[] = [];
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            isSendingTestEmail: false,
            testSuccess: false,
            alert: createEmptyNewAlert(),
            showNotifications: false
        };
    }

    async componentDidMount() {
        const channels: AlertChannel[] = await fetchAvailableChannels();
        this.options = channels.map(c =>
            ({ label: getAlertChannelName(c), value: c})
        );
        this.forceUpdate();
    }

    render() {
        return (
            <UI.FullScreenModal
                primaryBtnHandler={ () => this.doneClicked() }
                closeBtnHandler={() => this.closeClicked() }
                error={ this.state.error?.message }
                primaryBtnText={`Create`}
                header={`Create Alert`}
                className={`create-alert`}
            >
                <UI.Form>
                    <UI.Message appearance={"info"}>
                        Receive notifications about failed integration/migration runs or syncs directly on the selected channel. Getint will send notifications every 5 minutes.
                    </UI.Message>
                    <UI.Message appearance={'warning'}>Created alerts will be applied to all integrations / types mappings / fields mappings.</UI.Message>
                    <UI.FormGroup
                        label={`Name`}
                        isRequired={true}
                    >
                        <UI.Input
                            placeholder={`Alert name (will be included in notifications)`}
                            onChange={ (newValue: string) => this.changeName('name', newValue) }
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Alerts about`}
                        isRequired={true}
                    >
                        <UI.Checkbox
                            defaultValue={`true`}
                            uncheckedValue={`false`}
                            checkedValue={`true`}
                            label={`Failed runs`}
                            onChange={ () => this.changeFlag('forFailedRuns') }
                        />
                        <UI.Checkbox
                            defaultValue={`true`}
                            uncheckedValue={`false`}
                            checkedValue={`true`}
                            label={`Failed syncs`}
                            onChange={ () => this.changeFlag('forFailedSyncs') }
                        />
                        <UI.Checkbox
                            defaultValue={`true`}
                            uncheckedValue={`false`}
                            checkedValue={`true`}
                            label={`Warning logs`}
                            onChange={ () => this.changeFlag('forWarnLogs') }
                        />
                        <UI.Checkbox
                            defaultValue={`true`}
                            uncheckedValue={`false`}
                            checkedValue={`true`}
                            label={`Missing options mappings`}
                            onChange={ () => this.changeFlag('forMissingOptions') }
                        />
                    </UI.FormGroup>
                    <UI.FormGroup
                        label={`Channel`}
                        isRequired={true}
                    >
                        <UI.Dropdown
                            options={ this.options }
                            onChange={ (newValue: AlertChannel) => this.changeChannel(newValue) }
                        />
                    </UI.FormGroup>
                    { this.state.alert.channel === AlertChannel.Webhook && <UI.FormGroup>
                        {/*<UI.Message appearance={"info"}>*/}
                        {/*    <span>Webhooks creation on Slack: <DocumentationLink text={`Read more here`} url={`https://slack.com/help/articles/115005265063-Incoming-webhooks-for-Slack`} /></span>*/}
                        {/*</UI.Message>*/}
                        <UI.Input
                            isRequired={ true }
                            label={`Webhook url`}
                            placeholder={`Webhook url`}
                            onChange={ (newValue) => this.changeName('webhookUrl', newValue) }
                        />
                        <UI.Textarea
                            isRequired={ true }
                            rows={ 5 }
                            // label={`Webhook body`}
                            defaultValue={ `${ this.state.alert.webhookContent }` }
                            label={`Webhook body (JSON)`}
                            placeholder={`Webhook body`}
                            onChange={ (newValue) => this.changeName('webhookContent', newValue) }
                        />
                        { this.state.alert.webhookHeaders.length > 0 && <ul className={`headers-list`}>
                            { this.state.alert.webhookHeaders.map((header, index) => <li key={ index }>
                                { header.name }: { header.value }
                            </li>) }
                        </ul> }
                        <NotificationsWebhooksHeader
                            createHandler={ (name, value) => this.addHeader(name, value) }
                        />
                    </UI.FormGroup> }
                    { this.state.alert.channel === AlertChannel.Slack && <UI.FormGroup>
                        <UI.Message appearance={"info"}>
                            <span>How to create Slack notifications with Incoming Webhooks <DocumentationLink text={`Read more here`} url={`https://api.slack.com/messaging/webhooks`} /></span>
                        </UI.Message>
                        <UI.Input
                            placeholder={`Slack webhook url`}
                            onChange={ (newValue) => this.changeName('slackWebhookUrl', newValue) }
                        />
                    </UI.FormGroup> }
                    { this.state.alert.channel === AlertChannel.GetintEmail && <UI.FormGroup>
                        <UI.Input
                            placeholder={`Recipients`}
                            isRequired={ true }
                            label={`Recipients (comma separated email addresses)`}
                            onChange={ (newValue) => this.changeName('emailChannelTo', newValue) }
                        />
                        <UI.Input
                            placeholder={`Subject prefix`}
                            label={`Subject prefix`}
                            onChange={ (newValue) => this.changeName('emailChannelSubjectPrefix', newValue) }
                        />
                    </UI.FormGroup> }
                    { this.state.alert.channel === AlertChannel.JiraSmtp && <UI.FormGroup>
                        <UI.Input
                            placeholder={`Recipients`}
                            isRequired={ true }
                            label={`Recipients (comma separated email addresses)`}
                            onChange={ (newValue) => this.changeName('emailChannelTo', newValue) }
                        />
                        {/*<UI.Input*/}
                        {/*    placeholder={`From address`}*/}
                        {/*    label={`From address (email address of sender)`}*/}
                        {/*    onChange={ (newValue) => this.changeName('emailChannelFrom', newValue) }*/}
                        {/*/>*/}
                        <UI.Input
                            placeholder={`Subject prefix`}
                            label={`Subject prefix`}
                            onChange={ (newValue) => this.changeName('emailChannelSubjectPrefix', newValue) }
                        />
                    </UI.FormGroup> }
                    { this.state.alert.channel === AlertChannel.Email && <UI.FormGroup>
                        <UI.Input
                            placeholder={`SMTP Host`}
                            isRequired={ true }
                            label={`SMTP Host`}
                            onChange={ (newValue) => this.changeName('emailChannelSmtpHost', newValue) }
                        />
                        <UI.Input
                            placeholder={`SMTP Port`}
                            isRequired={ true }
                            label={`SMTP Port`}
                            onChange={ (newValue) => this.changeName('emailChannelSmtpPort', newValue) }
                        />
                        <UI.Input
                            placeholder={`Username`}
                            isRequired={ true }
                            label={`SMTP Username`}
                            onChange={ (newValue) => this.changeName('emailChannelSmtpUsername', newValue) }
                        />
                        <UI.Input
                            placeholder={`Password`}
                            isRequired={ true }
                            label={`SMTP Password`}
                            type={'password'}
                            onChange={ (newValue) => this.changeName('emailChannelSmtpPassword', newValue) }
                        />
                        <UI.Checkbox
                            defaultValue={ this.state.alert.emailChannelSsl + '' }
                            label={`SSL`}
                            onChange={ () => this.switchSsl() }
                            checkedValue={`true`} uncheckedValue={`false`}
                        />
                        <UI.Input
                            placeholder={`From address`}
                            isRequired={ true }
                            label={`From address (email address of sender)`}
                            onChange={ (newValue) => this.changeName('emailChannelFrom', newValue) }
                        />
                        <UI.Input
                            placeholder={`Recipients`}
                            isRequired={ true }
                            label={`Recipients (comma separated email addresses)`}
                            onChange={ (newValue) => this.changeName('emailChannelTo', newValue) }
                        />
                        <UI.Input
                            placeholder={`Subject prefix`}
                            label={`Subject prefix`}
                            onChange={ (newValue) => this.changeName('emailChannelSubjectPrefix', newValue) }
                        />
                        <br/>
                        <div className={`flex row two-cols`}>
                            <div className={`left`}>
                                <UI.Button
                                    disabled={ this.state.isLoading }
                                    isLoading={ this.state.isLoading }
                                    appearance={"secondary"}
                                    onClick={ () => this.sendTestEmailClicked() }
                                    text={`Send test email`}
                                />
                            </div>
                            <div className={`right info`}>
                                { this.state.testEmailError && <UI.Message appearance={"error"} message={ this.state.testEmailError.message} /> }
                                { this.state.testSuccess && <UI.Message
                                    appearance={"success"}
                                >Test email sent successfully </UI.Message> }
                            </div>
                        </div>
                    </UI.FormGroup> }
                </UI.Form>
            </UI.FullScreenModal>
        )
    }

    switchSsl() {
        const alert = this.state.alert;
        alert.emailChannelSsl = !alert.emailChannelSsl;
        this.setState({ alert });
    }

    sendTestEmailClicked() {
        this.setState({ testSuccess: false, isSendingTestEmail: true, testEmailError: undefined }, () => {
            sendTestEmail(this.state.alert)
                .then((result) => {
                    this.setState({ testSuccess: true, isSendingTestEmail: false });
                })
                .catch((error) => {
                    this.setState({ isSendingTestEmail: false, testEmailError: error});
                });
        });
    }

    changeChannel(channel: AlertChannel) {
        const alert = this.state.alert;
        alert.channel = channel;
        if (alert.channel === AlertChannel.Webhook) {
            alert.webhookContent = NOTIFICATIONS_WEBHOOK_INITIAL_CONTENT;
        }
        this.setState({ alert });
    }

    changeFlag(property: 'forFailedRuns' | 'forFailedSyncs' | 'forMissingOptions' | 'forWarnLogs') {
        const alert = this.state.alert;
        alert[property] = !alert[property];
        this.setState({ alert });
    }

    changeName(property: 'webhookUrl' | 'name' | 'slackWebhookUrl' | 'webhookContent' | 'emailChannelSmtpUsername' | 'emailChannelSmtpPassword' | 'emailChannelTo' | 'emailChannelSmtpHost' | 'emailChannelSmtpPort' | 'emailChannelFrom' | 'emailChannelSubjectPrefix', newValue: string) {
        const alert = this.state.alert;
        alert[property] = newValue;
        this.setState({ alert });
    }

    doneClicked(): Promise<boolean> {
        return new Promise((resolve) => {
            createAlert(this.state.alert)
                .then(() => {
                    resolve(true);
                    this.props.closeHandler(true);
                })
                .catch((error) => {
                    this.setState({ error });
                    resolve(false);
                });
        });
    }

    closeClicked() {
        this.props.closeHandler(false);
    }

    private addHeader(name: string, value: string) {
        const alert = this.state.alert;
        alert.webhookHeaders.push({ name, value });
        this.setState({ alert });
    }
}