import React from 'react';

import './buttons-bar.css';

type ButtonsBarProps = {
    primary?: any,
    secondary?: Array<any>
};

export class ButtonsBar extends React.Component<ButtonsBarProps> {
    render() {
        return <div className={`engrator-buttons-bar`}>
            <div className="col left">
                { this.props.secondary }
            </div>
            { this.props.primary && <div className="col right">
                { this.props.primary }
            </div> }
        </div>;
    }
}