import axios from 'axios';
import {AuthToken, CLUSTER_ADMIN_WORKSPACE, GetintWorkspace} from './auth-token';

import { extractErrorFromResponse } from './errors';

const prePath = (window as any).getInt?.jiraServerContextPath || '';
export const API_URL = prePath + (process.env.REACT_APP_API_HOST_URL || '/api');

const catchBlockHandler = (error: any, reject: any) => {
    if (error.response && error.response.status === 417) {
        AuthToken.remove();
        window.location.href = '/';
    } else if (error.response && error.response.status === 401) {
        if (window.location.href.indexOf('#sign-in') === -1) {
            AuthToken.remove();
            window.location.href = '/#sign-in';
        }
    } else {
        reject(extractErrorFromResponse(error));
    }
};

const requestAdditionalData = () => {
    const headers: any = {
        // 'X-Tenant': '8db10b43'
    };
    const workspace = AuthToken.getWorkspace();
    // If there is a default workspace and its not CLUSTER ADMIN. workspace
    // attach it to the header, so request will be scoped to particular tenant
    if (workspace && workspace.workspaceId !== CLUSTER_ADMIN_WORKSPACE.workspaceId) {
        headers['X-Tenant'] = workspace.workspaceName;
    }
    if (AuthToken.exists()) {
        headers['Authorization'] = AuthToken.get();
    }
    return {
        headers,
        timeout: 300 * 1000
    };
}

export const axiosWrapper = {
    post: (urlEndpoint: any, postData: any, customHeaders?: any) => {
        return new Promise((resolve, reject) => {
            const headers = requestAdditionalData();
            if (customHeaders) {
                Object.assign(headers, customHeaders);
            }
            return axios.post(API_URL + urlEndpoint, postData, headers)
            .then(response => resolve(response.data))
            .catch(error => catchBlockHandler(error, reject));
        });
    },
    postWithWholeResponse: (urlEndpoint: any, postData: any, customHeaders?: any) => {
        return new Promise((resolve, reject) => {
            const headers = requestAdditionalData();
            if (customHeaders) {
                Object.assign(headers, customHeaders);
            }
            return axios.post(API_URL + urlEndpoint, postData, headers)
                .then(response => resolve(response))
                .catch(error => catchBlockHandler(error, reject));
        });
    },
    postAsJson: (urlEndpoint: any, postData: any) => {
        return new Promise((resolve, reject) => {
            const headers = requestAdditionalData();
            headers.headers['Content-Type'] = 'application/json';
            return axios.post(API_URL + urlEndpoint, postData, headers)
            .then(response => resolve(response.data))
            .catch(error => catchBlockHandler(error, reject));
        });
    },
    put: (urlEndpoint: any, postData: any, headers?: any) => {
        return new Promise((resolve, reject) => {
            const additionalData = requestAdditionalData();
            if (headers) {
                Object.assign(additionalData.headers, headers);
            }
            return axios.put(API_URL + urlEndpoint, postData, additionalData)
                .then(response => resolve(response.data))
                .catch(error => catchBlockHandler(error, reject));
        });
    },
    delete: (urlEndpoint: any) => {
        return new Promise((resolve, reject) => {
            return axios.delete(API_URL + urlEndpoint, requestAdditionalData())
            .then(response => resolve(response.data))
            .catch(error => catchBlockHandler(error, reject));
        });
    },
    get: (urlEndpoint: any, pureResponse = false, headers?: any) => {
        return new Promise((resolve, reject) => {
            const additionalData = requestAdditionalData();
            if (headers) {
                Object.assign(additionalData.headers, headers);
            }
            return axios.get(API_URL + urlEndpoint, additionalData)
                .then(response => resolve(response.data))
                .catch(error => {
                    if (pureResponse) {
                        return reject(error);
                    }
                    catchBlockHandler(error, reject);
                });
        });
    },
    uploadFile: (urlEndpoint: any, formData: any) => {
        return new Promise((resolve, reject) => {
            const additionalData = requestAdditionalData();
            additionalData['headers']['Content-Type'] = 'multipart/form-data';
            axios.post(
                API_URL + urlEndpoint, formData, additionalData
            ).then(response => resolve(response.data))
            .catch(error => catchBlockHandler(error, reject));
        });
    },

    downloadFile: (urlEndpoint: any, fileName: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            const additionalData = requestAdditionalData();
            // additionalData['headers']['Content-Type'] = 'multipart/form-data';
            (additionalData as any)['responseType'] = 'blob';
            console.log(additionalData);
            const url = API_URL + urlEndpoint;
            return axios.get(url, additionalData)
                .then(response => {
                    const href = URL.createObjectURL(response.data);

                    // create "a" HTLM element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute("target", "blank");
                    link.setAttribute('download', fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url)
                })
                .catch(error => catchBlockHandler(error, reject));
        });
    }
};
