import React from "react";

import {DropdownOption} from "./dropdown-option.type";
import {FilterChangeHandler} from "./filter-change-handler.type";
import {UI} from "src/engrator-core";
import {Icon20} from "../../../engrator-core/ui";
import {fetchSystemInfo} from "../../top-navigation/rest-api";

type Props = {
    filterChangeHandler: FilterChangeHandler,
    title: string,
    defaultValues: any;
    options?: DropdownOption[];
    isSingleSelect?: boolean;
    listenForChanges?: boolean;
};

type State = {
    selectedValues: string[],
    optionsVisible: boolean
};

type FilterValue = {
    selectedValues: string[],
};

export class DropdownFilter extends React.Component<Props, State> {
    options: DropdownOption[] = [];
    private wrapperRef: any;

    private filterValue: FilterValue = {
        selectedValues: [],
    };

    constructor(props: Props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.options = (this.props.options) ? this.props.options : [
            {value: 'Started', label: 'Started', isSelected: false},
            {value: 'Success', label: 'Success', isSelected: false},
            {value: 'Failure', label: 'Failure', isSelected: false}];
        this.state = { selectedValues: [], optionsVisible: false };
    }

    setWrapperRef(node: any) {
        this.wrapperRef = node;
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.documentClick);
        document.removeEventListener('touchstart', this.documentClick);
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.documentClick.bind(this));
        document.addEventListener('touchstart', this.documentClick.bind(this));
        this.selectOptions();
    }

    private selectOptions() {
        if (this.props.defaultValues && this.props.defaultValues.selectedValues) {
            this.options.forEach(option => {
                option.isSelected = (this.props.defaultValues.selectedValues.indexOf(option.value)) >= 0;
            })
            const values = Array.isArray(this.props.defaultValues.selectedValues)
                ? this.props.defaultValues.selectedValues
                : this.props.defaultValues.selectedValues.split(',');
            this.setState({selectedValues: values});
        }
    }

    documentClick(event: any) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.optionsVisible) {
            this.changeOptionsVisibility(false);
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.listenForChanges) {
            if (this.state.selectedValues[0] && JSON.stringify(this.props.defaultValues.selectedValues[0]) !== JSON.stringify(this.state.selectedValues[0])) {
                this.selectOptions();
            }
        }
    }

    optionClicked(option: DropdownOption) {
        const selectedValues = this.state.selectedValues;
        if (this.props.isSingleSelect === true) {
            this.options?.forEach(o => {
                if (o.value !== option.value) {
                    o.isSelected = false;
                }
            });
        }
        // if (selectedValues.indexOf(option.value) >= 0) {
        //     selectedValues.splice(selectedValues.indexOf(option.value), 1);
        //     option.isSelected = false;
        // } else {
        //     selectedValues.push(option.value);
        //     option.isSelected = true;
        // }
        option.isSelected = !option.isSelected;
        this.filterValue.selectedValues = this.options.filter(option => option.isSelected).map(option => option.value);
        this.setState({ selectedValues: this.filterValue.selectedValues });
        this.props.filterChangeHandler(this.filterValue);
        if (this.props.isSingleSelect === true) {
            this.changeOptionsVisibility(false);
        }
    }

    render() {
        return <div className={`search-filter dropdown`} ref={ this.setWrapperRef }>
                <label
                    onClick={ () => this.changeOptionsVisibility(true)}
                    className={`title ${ (this.state.selectedValues.length > 0) ? 'selected' : '' }`}
                >
                    { !this.state.selectedValues.length && this.props.title }
                    { this.state.selectedValues.length > 0 && this.getSelectedOptionsShortInfo() }
                    <UI.Icon icon20={ Icon20.ChevronDown } />
                </label>
                {/*{ !this.state.selectedValues.length && <div className={`placeholder`} onClick={ () => this.changeOptionsVisibility(true)} >Click to select</div> }*/}
                {/*{ this.state.selectedValues.length >= 0 && <div className={`selected-values`} onClick={ () => this.changeOptionsVisibility(true)} >{ this.getSelectedOptionsShortInfo() }</div> }*/}
                { this.state.optionsVisible && <div className={`popup`}>
                    <ul>
                        { this.options.map((option, index) =>
                            <li key={ index } className={ (option.isSelected ? `selected` : ``)} onClick={ () => this.optionClicked(option) }>
                                { option.isSelected && <UI.Icon icon20={ Icon20.SquareChecked} /> }
                                { !option.isSelected && <UI.Icon icon20={ Icon20.Square } /> }
                                { option.label }
                            </li>
                        )}
                    </ul>
                    { !!!this.props.isSingleSelect && <UI.ButtonsBar
                        primary={ <UI.Button appearance={`link-inline`} onClick={() => this.unselectAll() } text={`clear`} /> }
                        // secondary={ [<UI.Button appearance={`link-inline`} onClick={() => this.unselectAll() } text={`clear`} /> ]}
                    /> }
                </div> }
        </div>
    }

    private isOptionSelected(optionValue: string): boolean {
        return this.state.selectedValues.indexOf(optionValue) >= 0;
    }

    private changeOptionsVisibility(shouldBeVisible: boolean) {
        this.setState({ optionsVisible: shouldBeVisible });
    }

    private unselectAll() {
        this.options.forEach(option => {
            option.isSelected = false;
        });
        this.setState({ selectedValues: [] });
    }

    private getSelectedOptionsShortInfo(): string {
        const MAX_LENGTH = 20;
        let info = this.options.filter(option => option.isSelected).map(option => option.value).join(', ');
        if (info.length > MAX_LENGTH) {
            return info.substring(0, MAX_LENGTH) + `...`;
        }
        return info;
    }
}