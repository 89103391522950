export enum UserActivityAction {
    ConfirmedWelcomeWizard = 'ConfirmedWelcomeWizard',
    ClickedAddConnection = 'ClickedAddConnection',
    SelectedSoftware = 'SelectedSoftware',
    SelectedApp = 'SelectedApp',
    WasOnDashboard = 'WasOnDashboard',
    WasOnIntegrationsList = 'WasOnIntegrationsList',
    WasOnCreateIntegrationView = 'WasOnCreateIntegrationView',
    ClickedSelectApp = 'ClickedSelectApp',
    ClickedCreateIntegration = 'ClickedCreateIntegration',
    ClickedCreateNewConnection = 'ClickedCreateNewConnection',
    CreatedConnection = 'CreatedConnection',
    IntegrationCreatedCongrats = 'IntegrationCreatedCongrats',
    SyncGuideIntegrationName = 'SyncGuideIntegrationName',
    SyncGuideMigration = 'SyncGuideMigration',
    SyncGuideVideo = 'SyncGuideVideo',
    SyncGuideEditApp = 'SyncGuideEditApp',
    NewUI102023Prompt = 'NewUI102023Prompt'
}
