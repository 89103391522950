import React from 'react';
import {Icon} from "./icon";

type Props = {
    text: string,
    url: string
}

type State = {
}

export class DocumentationLink extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <a
            className={`engrator-ui-documentation-link`}
            target={"_blank"}
            href={ this.props.url }>
                { this.props.text } <Icon icon={"external-link"} />
        </a>;
    }
}