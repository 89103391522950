import { axiosWrapper } from 'src/engrator-core';
import {SoftwareName} from "../../../../../../software";
import {DropdownOption} from "../../../../../../engrator-core/ui";

const ENDPOINT_URL = '/integration/suites';

export type ImportValidateResult = {
    leftSoftwareName: SoftwareName;
    leftConnections: DropdownOption[];
    rightSoftwareName: SoftwareName;
    rightConnections: DropdownOption[];
};

export type ScriptToImport = {
    name: string;
    leftConnectionId: number;
    rightConnectionId: number;
    script: string;
};

export function getScriptForExport(integrationId: number): Promise<string>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + `/${integrationId}/export`)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function validateImportedScript(script: string): Promise<ImportValidateResult>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/import/validate`, { script: JSON.parse(script) })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export function importScript(scriptToImport: ScriptToImport): Promise<number>  {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/import`, scriptToImport )
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}
