import { DropdownOption } from '../../../../../engrator-core/ui';
import { SmartIntDefinitionTypeMapping } from '../../definition/smart-int-definition--type-mapping.type';
import { createDefaultAttachemntsConfiguration, getDefaultHierarchySyncConfiguration, SmartIntConfigurationType } from '../../definition/smart-int-definition-configuration.type';
import { SmartIntDirection } from '../../definition/smart-int-direction.type';
import { PropertyMapping } from '../property-mapping.type';
import PropertyDataType from '../../../../../generic/artifacts/property-data-type';
import { getDefaultSkipMapping } from '../components/value-mappings/default-mappping.type';
import {SoftwareName} from "../../../../../software";

export function createTypeMapping(typeOnLeftSide: DropdownOption, typeOnRightSide: DropdownOption, leftAppName: string, rightAppName: string): SmartIntDefinitionTypeMapping {
    const isMonday = (leftAppName === 'Monday' || rightAppName === 'Monday');
    const isHubspot = (leftAppName === SoftwareName.Hubspot || rightAppName === SoftwareName.Hubspot);
    const isNotion = (leftAppName === SoftwareName.Notion || rightAppName === SoftwareName.Notion);
    const isAirtable = (leftAppName === SoftwareName.Airtable || rightAppName === SoftwareName.Airtable);
    const properties = [];
    const titlePropertyMapping: PropertyMapping = {
        direction: SmartIntDirection.Both,
        left: {
            dataType: PropertyDataType.String, id: 'title', name: 'Title'
        },
        options: {
            left: {
                filterOptionsQuery: '',
                onCreate: true,
                onUpdate: true,
                predefinedValue: '',
                postCreate: false
            },
            right: {
                filterOptionsQuery: '',
                onCreate: true,
                onUpdate: true,
                predefinedValue: '',
                postCreate: false
            }
        },
        right: {
            dataType: PropertyDataType.String, id: 'title', name: 'Title'
        },
        groups: [],
        defaults: {
            left: getDefaultSkipMapping(),
            right: getDefaultSkipMapping(),
        },
        empty: { left: '', right: '' },
        additional: {
            azureEmailIsJiraUsername: false
        }
    };
    if (!isHubspot && !isAirtable) {
        properties.push(titlePropertyMapping);
    }
    if (!isMonday && !isHubspot && !isNotion && !isAirtable) {
        const descriptionPropertyMapping: PropertyMapping = {
            direction: SmartIntDirection.Both,
            left: {
                dataType: PropertyDataType.String, id: 'description', name: 'Description'
            },
            options: {
                left: {
                    filterOptionsQuery: '',
                    onCreate: true,
                    onUpdate: true,
                    predefinedValue: '',
                    postCreate: false
                },
                right: {
                    filterOptionsQuery: '',
                    onCreate: true,
                    onUpdate: true,
                    predefinedValue: '',
                    postCreate: false
                }
            },
            right: {
                dataType: PropertyDataType.String, id: 'description', name: 'Description'
            },
            groups: [],
            defaults: {
                left: getDefaultSkipMapping(),
                right: getDefaultSkipMapping(),
            },
            empty: {left: '', right: ''},
            additional: {
                azureEmailIsJiraUsername: false
            }
        };
        properties.push(descriptionPropertyMapping);
    }

    return {
        direction: SmartIntDirection.Both,
        propertiesConfiguration: {
            properties,
            configurationType: SmartIntConfigurationType.PropertyMappings
        },
        commentsConfiguration: {
            asPrivate: { left: false, right: false },
            filters: { left: {conditions: [], mode: 'Pass'}, right: {conditions: [], mode: 'Pass'} },
            insertOriginalAuthor: true, insertOriginalCreationDate: true, disablePairingComment: true,
            insertItemId: true,
            enabled: true,
            configurationType: SmartIntConfigurationType.Comments,
            conditions: { left: '', right: '' }
        },
        ciConfiguration: {
            configurationType: SmartIntConfigurationType.CI,
            pullRequests: {
                enabled: false,
                captureRegExp: ''
            }
        },
        hierarchyConfiguration: getDefaultHierarchySyncConfiguration(),
        attachmentsConfiguration: {
            configurationType: SmartIntConfigurationType.Attachments,
            enabled: false,
            onlyPublicCommentsAttachments: {
                left: false,
                right: false
            },
            insertInPublicComment: {
                left: false,
                right: false
            },
            left: createDefaultAttachemntsConfiguration(),
            right: createDefaultAttachemntsConfiguration()
        },
        left: {
            id: typeOnLeftSide.value,
            name: typeOnLeftSide.label,
            internal: typeOnLeftSide.object
        },
        right: {
            id: typeOnRightSide.value,
            name: typeOnRightSide.label,
            internal: typeOnRightSide.object
        },
        advancedConfiguration: {
            configurationType: SmartIntConfigurationType.Advanced,
            postCreate: {
                left: '',
                right: ''
            }
        },
        statusTransitionConfiguration: {
            statusFieldsIds: { left: '', right: ''},
            configurationType: SmartIntConfigurationType.StatusTransition,
            enabled: true,
            groups: [],
            defaults: { left: getDefaultSkipMapping(), right: getDefaultSkipMapping() }
        },
        status: 'Enabled'
    }
}

export function isTypeMappingEnabled(typeMapping: SmartIntDefinitionTypeMapping): boolean {
    // Its enabled ifstatus is not disabled
    return typeMapping.status !== 'Disabled';
}
