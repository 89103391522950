import React from 'react';
import {UI} from 'src/engrator-core';
import {hasCompletedAction} from "./rest-api";
import {registerUserActivityAction, UserActivityAction} from "../system/user-activity";

type Props = {};
type State = {
    show: boolean;
};

export class NewUI102023Prompt extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show: false
        };
    }

    componentDidMount() {
        hasCompletedAction(UserActivityAction.NewUI102023Prompt)
            .then((res) => this.setState({ show:!res }))
            .catch(err => {});
    }

    render() {
        if (!this.state.show) {
            return <React.Fragment/>
        }
        return <UI.Modal
            highestIndex={ true }
            isWide={ false }
            header={`🎉 Introducing Our New UI Update! 🎉`}
        >
            <p>Hello there! We're excited to present our refreshed and streamlined user interface! We've made several enhancements to optimize your experience, especially for our Jira users. Navigate seamlessly between Jira tasks and our integration tools with fewer clicks and greater efficiency.
                Here's a quick rundown of what's new:
                <ul>
                    <li>Cleaner dashboard for easier data tracking.</li>
                    <li>Improved Jira menu compatibility for smoother transitions.</li>
                    <li>Direct app connections right at your fingertips.</li>
                    <li>And more...</li>
                </ul>
                Take a moment to explore and let us know what you think. Your feedback helps us serve you better! <a href={`https://www.getint.io/blog/embracing-change-introducing-our-redesigned-ipaas-dashboard`}>Read more here</a><br/><br/>
                Happy Integrating! 🚀
            </p>
            <UI.Button
                text={`Okey!`}
                onClick={ () => this.okeyClicked() }
            />
        </UI.Modal>;
    }

    private okeyClicked() {
        registerUserActivityAction(UserActivityAction.NewUI102023Prompt);
        this.setState({ show: false });
    }
}
