import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';
import * as serviceWorker from './app/service-worker';
import { DevApp } from './dev';

if (window.location.href.indexOf("?dev=true") >= 0) {
    ReactDOM.render( < DevApp / >, document.getElementById('root'));
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
