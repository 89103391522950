import React from 'react';
import { Button } from './button';
import {Icon} from "./icon";

type Props = {
    currentPage: number,
    totalPages: number,
    onPageChangeHandler: (pageNumber: number) => void
};

export class Pagination extends React.Component<Props> {
    render() {
        if (this.props.totalPages === 0) {
            return <React.Fragment></React.Fragment>
        }
        return <div className={`engrator-ui-pagination`}>
            {this.props.currentPage > 0 &&
            <Button appearance={"secondary"}
                    onClick={() => this.props.onPageChangeHandler(this.props.currentPage - 1)}
                    text={ ``}
                    icon={ <Icon icon={"prev"} />} />
            }
            <div className={`page-info`}>{ this.props.currentPage + 1 } of { this.props.totalPages }</div>
            {this.props.currentPage + 1 < this.props.totalPages &&
            <Button appearance={"secondary"}
                    onClick={() => this.props.onPageChangeHandler(this.props.currentPage + 1)}
                    text={ ``}
                    icon={ <Icon icon={"next"} />} />
            }
        </div>
    }
};