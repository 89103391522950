import React from 'react';

import { UI } from 'src/engrator-core';

export enum CITypeMappingNavBarItemName {
    PullRequests = 'Pull Requests',
}

type Props = {
    selectedItem: CITypeMappingNavBarItemName;
    onChange: (tabItemName: CITypeMappingNavBarItemName) => void;
};

export class CITypeMappingNavBar extends React.Component<Props> {
    render() {
        return <React.Fragment>
            <div>
                <UI.TabBar>
                    <UI.TabButton
                        onClick={() => this.props.onChange(CITypeMappingNavBarItemName.PullRequests)}
                        text={CITypeMappingNavBarItemName.PullRequests}
                        isSelected={this.props.selectedItem === CITypeMappingNavBarItemName.PullRequests}/>
                </UI.TabBar>
            </div>
        </React.Fragment>
    }
}
