import {PredefinedPropertyDefinition, PropertyDefinition} from '../../../../generic/artifacts';
import {SmartIntDirection} from '../definition/smart-int-direction.type';
import {DefaultMapping, getDefaults} from './components/value-mappings/default-mappping.type';
import {Formatter} from './components/value-mappings/formatters/formatter.type';
import {ValueMappingsGroup} from './components/value-mappings/value-mappings-group.type';

export type PropertyMappingOptions = {
    onCreate: boolean;
    onUpdate: boolean;
    postCreate: boolean;
    predefinedValue: string;
    predefinedValueLabel?: string;
    filterOptionsQuery: string;
};

export type PropertyMapping = {
    isNewlyCreated?: boolean;   // property used for animation on UI only
    left: PropertyDefinition;
    right: PropertyDefinition;
    direction: SmartIntDirection;
    options: {
        left: PropertyMappingOptions,
        right: PropertyMappingOptions,
    },
    formatters?: {
        left: Formatter[],
        right: Formatter[]
    },
    groups: ValueMappingsGroup[],
    defaults: {
        left: DefaultMapping;
        right: DefaultMapping;
    },
    empty: {
        left: string;
        right: string;
    },
    additional: {
        azureEmailIsJiraUsername: boolean;
    }
}

export function createDefaultPropertyMappingOptions(): PropertyMappingOptions {
    return {
        postCreate: true,
        onCreate: true,
        onUpdate: true,
        filterOptionsQuery: '',
        predefinedValue: ''
    };
}

export function getDirectionForNewMapping(leftProperty: PropertyDefinition, rightProperty: PropertyDefinition): SmartIntDirection {
    if (leftProperty.options?.readonly === true || leftProperty.id === PredefinedPropertyDefinition.id) {
        return SmartIntDirection.ToRight;
    } else if (rightProperty.options?.readonly || rightProperty.id === PredefinedPropertyDefinition.id) {
        return SmartIntDirection.ToLeft;
    }
    // If left is select field and right not, only to right
    if (leftProperty.options?.selectable === true && rightProperty.options?.selectable === false) {
        return SmartIntDirection.ToRight;
    }
    // If right is select field and left not, only to right
    if (rightProperty.options?.selectable === true && leftProperty.options?.selectable === false) {
        return SmartIntDirection.ToLeft;
    }

    return SmartIntDirection.Both;
}

export function createPropertyMapping(leftProperty: PropertyDefinition, rightProperty: PropertyDefinition): PropertyMapping {
    const defaults = getDefaults(leftProperty, rightProperty);
    const direction = getDirectionForNewMapping(leftProperty, rightProperty);
    return {
        direction,
        left: leftProperty,
        options: {
            left: {
                filterOptionsQuery: '',
                onCreate: true,
                onUpdate: true,
                postCreate: false,
                predefinedValue: ''
            },
            right: {
                filterOptionsQuery: '',
                onCreate: true,
                onUpdate: true,
                postCreate: false,
                predefinedValue: ''
            }
        },
        right: rightProperty,
        groups: [],
        defaults: defaults,
        empty: { left: '', right: '' },
        additional: {
            azureEmailIsJiraUsername: false
        }
    };
}
