import React from 'react';

import { UI } from 'src/engrator-core';
import { SoftwareName } from 'src/software';
import { Formatter } from './formatter.type';
import { concatFormatters } from './formatters-concat';
import { fetchFormatters } from './rest-api';

type Props = {
    fromAppName: SoftwareName;
    toAppName: SoftwareName;
    formatters: Formatter[];
};

type State = {
    isLoading: boolean;
    availableFormatters: Formatter[] | undefined;
};

export class AddFormatter extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            availableFormatters: undefined,
            isLoading: false
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            fetchFormatters(this.props.fromAppName, this.props.toAppName).then(availableFormatters => {
                const joinedFormatters = concatFormatters(this.props.formatters, availableFormatters);
                this.setState({ isLoading: false, availableFormatters: joinedFormatters });
            }).catch(err => {
                this.setState({ isLoading: false });
            });
        });
    }

    render() {
        return <div>
            { this.state.isLoading && <UI.Loader 
                visible={ true } 
                appearance={ `darkgray` }
            /> }
            { this.state.availableFormatters !== undefined && <React.Fragment>
                { this.state.availableFormatters.map((formatter, index) => <UI.Checkbox
                    checkedValue={`true`}
                    uncheckedValue={`false`}
                    onChange={ (newValue) => this.formatterChanged(newValue, formatter) }
                    label={ formatter.name }
                    defaultValue={ `${formatter.checked}`}
                /> )}
            </React.Fragment> }
        </div>;
    }

    private formatterChanged(newValue: string, formatter: Formatter) {
        formatter.checked = newValue === 'true';
        this.forceUpdate();
        this.props.formatters.splice(0, this.props.formatters.splice.length);
        this.state.availableFormatters?.forEach(formatter => {
            if (formatter.checked) {
                this.props.formatters.push(formatter);
            }
        });
    }
}
