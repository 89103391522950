import React from 'react';


import { UI } from 'src/engrator-core';
import { SmartIntDefinitionTypeMapping } from '../../../definition/smart-int-definition--type-mapping.type';
import { AppsSupport } from '../../visual-integration-designer';
import { DropdownOption } from '../../../../../../engrator-core/ui';
import { CISupport } from '../../../ci/smartints-ci-support';
import { Status } from '../../../../../../software/generic/status-transition/status.type';

type Props = {
    appsSupport: AppsSupport;
    mapping: SmartIntDefinitionTypeMapping;
    ciSupport: CISupport;
};
type State = {
    statuses: DropdownOption[] | undefined;
    pattern: string | undefined;
}

export class CiPullRequestsConfiguration extends React.Component<Props, State> {
    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = {
            statuses: undefined,
            pattern: undefined
        };
    }

    componentDidMount(): void {
        this.props.ciSupport.fetchStatuses().then(statuses => {
            const options: DropdownOption[] = statuses.map(status => ({
                label: status.name,
                value: status.id,
                object: status
            }));
            this.setState({ statuses: options }, () => {
                if (!this.props.mapping.ciConfiguration?.pullRequests.captureRegExp) {
                    this.props.ciSupport.fetchRelatedItemSearchPattern().then(pattern => {
                        this.props.mapping.ciConfiguration!.pullRequests.captureRegExp = pattern;
                        this.forceUpdate();
                    });
                }
            });
        });
    }

    render() {
        return <div className={``}>
            <UI.Form errors={[]}>
                <UI.FormSection
                    label={``}
                >
                    <UI.FormGroup
                        label={`Transition related item when pull request is closed`}
                        description={`Configure this section to transition item which are referenced by Pull Requests (e.g. Jira issue key is placed in Pull Request title).`}
                    >
                        <UI.Checkbox
                            defaultValue={`${this.props.mapping.ciConfiguration?.pullRequests.enabled}`}
                            checkedValue={`true`}
                            uncheckedValue={`false`}
                            onChange={() => this.switchPullRequestsFlag()}
                            label={`Enable`}
                        />
                        {this.props.mapping.ciConfiguration?.pullRequests.enabled && <React.Fragment>
                            {this.state.statuses !== undefined && <UI.Dropdown
                                defaultValue={this.props.mapping.ciConfiguration!.pullRequests.itemStatus?.id}
                                label={`When Pull Request is closed, transition item to status`}
                                options={this.state.statuses}
                                onChange={this.setStatus.bind(this)}
                            />}
                            {this.state.statuses === undefined && <UI.Loader visible={true} appearance={'darkgray'}/>}
                            <UI.Input
                                label={`Regular expression to extract related item id from pull request`}
                                defaultValue={this.props.mapping.ciConfiguration?.pullRequests.captureRegExp}
                                placeholder={`Regular expression to extract related item id from pull request`}
                                onChange={(newValue) => this.changeCaptureRegExp(newValue)}/>
                        </React.Fragment>}
                    </UI.FormGroup>
                </UI.FormSection>
            </UI.Form>
        </div>;
    }

    private setStatus(option: DropdownOption, status: Status) {
        this.props.mapping.ciConfiguration!.pullRequests.itemStatus = status;
    }

    private switchPullRequestsFlag() {
        this.props.mapping.ciConfiguration!.pullRequests.enabled = !!!this.props.mapping.ciConfiguration?.pullRequests.enabled;
        this.forceUpdate();
    }

    private changeCaptureRegExp(newValue: string) {
        this.props.mapping.ciConfiguration!.pullRequests.captureRegExp = newValue;
    }
}
