import { Status } from '../../../../software/generic/status-transition/status.type';
import { SmartIntTrigger } from '../definition/smart-int-trigger.type';
import { SoftwareName } from '../../../../software';
import { fetchPattern, fetchStatuses } from './ci-rest-api';

export interface CISupport {
    fetchStatuses(): Promise<Status[]>;

    fetchRelatedItemSearchPattern(): Promise<string>;
}

export function createCISupport(appName: SoftwareName, trigger: SmartIntTrigger): CISupport {
    return {
        fetchRelatedItemSearchPattern(): Promise<string> {
            return fetchPattern(appName, trigger);
        },
        fetchStatuses(): Promise<Status[]> {
            return fetchStatuses(appName, trigger);
        }
    }
}
