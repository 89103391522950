import React from 'react';
import {FieldError, UI} from 'src/engrator-core';
import {SmartIntDefinition} from "../../../definition";
import {AdvancedConfiguration} from "../advanced-configuration";
import {Scripting} from "./scripting";
import {validateScriptingConfiguration} from "./rest-api";
import {CustomProperties} from "./customProperties";

type Props = {
    integrationDefinition: SmartIntDefinition;
    closeHandler: () => void;
    persistConfiguration: (newConfiguration: AdvancedConfiguration) => void;
};
type State = {
    isLoading: boolean;
    currentTab: 'scripting' | 'customproperties'
    error?: FieldError;
    configuration: AdvancedConfiguration;
};

export class AdvancedConfigurationComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const configuration: AdvancedConfiguration = JSON.parse(JSON.stringify(this.props.integrationDefinition.advancedConfiguration));
        if (!configuration.scripting.onBeforeCommentCreated) {
            configuration.scripting.onBeforeCommentCreated = {
                left: '',
                right: ''
            }
        }
        if (!configuration.customProperties) {
            configuration.customProperties = {
                serviceNowImageBaseUrl: ''
            };
        }
        this.state ={ configuration, isLoading: false, currentTab: 'scripting' };
    }

    render() {
        return <UI.FullScreenModal
            maximized={true}
            header={`Advanced Configuration`}
            closeBtnHandler={ () => this.props.closeHandler() }
            primaryBtnHandler={ () => this.applyClicked() }
        >
            <UI.TabBar>
                <UI.TabButton onClick={ () => this.setState({ currentTab: 'scripting'}) } text={`Scripting`} isSelected={ this.state.currentTab === 'scripting' } />
                <UI.TabButton onClick={ () => this.setState({ currentTab: 'customproperties'}) }  text={`Custom properties`} isSelected={ this.state.currentTab === 'customproperties' } />
            </UI.TabBar>
            <UI.Message appearance={`error`}>
                {this.state.error?.message}
            </UI.Message>
            { this.state.currentTab === 'customproperties' && <CustomProperties
                properties={ this.state.configuration.customProperties }
                leftApp={ this.props.integrationDefinition.triggers.left?.app }
                rightApp={ this.props.integrationDefinition.triggers.right?.app }
            /> }
            { this.state.currentTab === 'scripting' && <Scripting
                leftApp={ this.props.integrationDefinition.triggers.left?.app }
                rightApp={ this.props.integrationDefinition.triggers.right?.app }
                scripting={ this.state.configuration.scripting }
            /> }
        </UI.FullScreenModal>;
    }

    private applyClicked(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true}, () => {
                validateScriptingConfiguration(this.state.configuration.scripting)
                    .then(() => {
                        this.props.persistConfiguration(this.state.configuration);
                        resolve(true);
                    })
                    .catch((error: FieldError) => {
                        this.setState({isLoading: false, error});
                        resolve(false);
                    });
            });
        });
    }
}
