import React from "react";
import {ReportName} from "./report-name.type";
import {DropdownFilter, InputFilter, NumberRangeFilter} from "./filters";
import {FilterName} from "./filter-name.type";

export function createReportingFilters(reportName: ReportName, initialFilters: any, getFilterChangeHandler: any): any {
    if (reportName === ReportName.StepsExecutions) {
        return <React.Fragment>
            <InputFilter defaultValue={ getQueryDefaultValue(initialFilters[FilterName.Query]) }  placeholder={`Type Step Desc.`} title={`Search phrase`} filterChangeHandler={ getFilterChangeHandler(FilterName.Query)} />
            <NumberRangeFilter defaultValue={ initialFilters[FilterName.SyncedArtifacts]} title={`Synced Items`} filterChangeHandler={ getFilterChangeHandler(FilterName.SyncedArtifacts)} />
            <NumberRangeFilter defaultValue={ initialFilters[FilterName.ExecutionTime]} title={`Time (sec.)`} filterChangeHandler={ getFilterChangeHandler(FilterName.ExecutionTime)} />
            <DropdownFilter defaultValues={ initialFilters[FilterName.Status] } title={`Status`} filterChangeHandler={ getFilterChangeHandler(FilterName.Status)} />
        </React.Fragment>
    }
    if (reportName === ReportName.PersistedArtifacts) {
        return <React.Fragment>
            <InputFilter defaultValue={ getQueryDefaultValue(initialFilters[FilterName.Query]) }  placeholder={`Type Identifier or Step Desc.`} title={`Search phrase`} filterChangeHandler={ getFilterChangeHandler(FilterName.Query)} />
        </React.Fragment>
    }
    if (reportName === ReportName.PerformedFlows) {
        return <React.Fragment>
            <InputFilter defaultValue={ getQueryDefaultValue(initialFilters[FilterName.Query]) }  placeholder={`Type Trigger Ident.`} title={`Search phrase`} filterChangeHandler={ getFilterChangeHandler(FilterName.Query)} />
            <NumberRangeFilter defaultValue={ initialFilters[FilterName.SyncedArtifacts]} title={`Synced Items`} filterChangeHandler={ getFilterChangeHandler(FilterName.SyncedArtifacts)} />
            <NumberRangeFilter defaultValue={ initialFilters[FilterName.ExecutionTime]} title={`Time (sec.)`} filterChangeHandler={ getFilterChangeHandler(FilterName.ExecutionTime)} />
            <InputFilter defaultValue={ getQueryDefaultValue(initialFilters[FilterName.DateRange]) }  placeholder={`e.g. 2024-01-01 - 2024-01-12`} title={`Execution date`} filterChangeHandler={ getFilterChangeHandler(FilterName.DateRange)} />
            <DropdownFilter defaultValues={ initialFilters[FilterName.Status] } title={`Status`} filterChangeHandler={ getFilterChangeHandler(FilterName.Status)} />
        </React.Fragment>
    } else if (reportName === ReportName.SyncedArtifacts) {
        return <React.Fragment>
            <InputFilter defaultValue={ getQueryDefaultValue(initialFilters[FilterName.Query]) }  placeholder={`Type Identifier`} title={`Search phrase`} filterChangeHandler={ getFilterChangeHandler(FilterName.Query)} />
        </React.Fragment>
    }
    return <React.Fragment>
        <InputFilter defaultValue={ getQueryDefaultValue(initialFilters[FilterName.Query]) } placeholder={`Name, Integration ID or run ID`} title={`Search phrase`} filterChangeHandler={ getFilterChangeHandler(FilterName.Query)} />
        <NumberRangeFilter defaultValue={ initialFilters[FilterName.FailedFlows]} title={ `Failed Syncs` } filterChangeHandler={ getFilterChangeHandler(FilterName.FailedFlows)} />
        <NumberRangeFilter defaultValue={ initialFilters[FilterName.SyncedArtifacts]} title={`Synced Items`} filterChangeHandler={ getFilterChangeHandler(FilterName.SyncedArtifacts)} />
        <NumberRangeFilter defaultValue={ initialFilters[FilterName.ExecutionTime]} title={`Time (sec.)`} filterChangeHandler={ getFilterChangeHandler(FilterName.ExecutionTime)} />
        <DropdownFilter defaultValues={ initialFilters[FilterName.Status] } title={`Status`} filterChangeHandler={ getFilterChangeHandler(FilterName.Status)} />
        <DropdownFilter
            options={[
                {value: 'Sync', label: 'Sync', isSelected: false},
                {value: 'Migration', label: 'Migration', isSelected: false},
                {value: 'Archiving', label: 'Archiving', isSelected: false},
            ]}
            defaultValues={ initialFilters[FilterName.RunMode] }
            title={`Mode`}
            filterChangeHandler={ getFilterChangeHandler(FilterName.RunMode)}
        />
    </React.Fragment>
}

function getQueryDefaultValue(queryObject: any) {
    return (queryObject && queryObject.query) ? queryObject.query : '';
}