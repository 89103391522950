import React from 'react';
import { UI } from 'src/engrator-core';
import { SelectAppStep } from './select-app-step';
import { SelectConnectionDetailsStep } from './select-connection-details-step';
import { ISoftwareFactory, SoftwareName } from '../../../../../software';
import { SmartIntTrigger } from '../../definition/smart-int-trigger.type';
import { registerUserActivityAction, UserActivityAction } from '../../../../system/user-activity';

type State = {
    currentStep: number;
};

type Props = {
    close: () => void;
    softwareFactory: ISoftwareFactory;
    onAppSelected: (appName: SoftwareName, connectionId: number, trigger: SmartIntTrigger) => void;
}

export class AppSelectionWizard extends React.Component<Props, State> {
    private data : {
        softwareName?: SoftwareName;
    } = {};

    constructor(props: Props) {
        super(props);
        this.state = { currentStep: 1 };
    }

    render() {
        return <React.Fragment>
            <div className={`app-selection-wizard`}>
                {this.state.currentStep === 1 && <SelectAppStep
                    softwareFactory={this.props.softwareFactory}
                    optionSelected={this.appSelectedFromList.bind(this)}
                />}
                {this.state.currentStep === 2 && <SelectConnectionDetailsStep
                    softwareFactory={this.props.softwareFactory}
                    onConnectionSetUp={this.onConnectionSetUp.bind(this)}
                    softwareName={this.data.softwareName!}
                />}
            </div>
        </React.Fragment>
    }

    private appSelectedFromList(softwareName: SoftwareName) {
        registerUserActivityAction(UserActivityAction.SelectedSoftware);
        this.data.softwareName = softwareName;
        this.setState({ currentStep: 2 });
    }

    private onConnectionSetUp(connectionId: number, trigger: SmartIntTrigger) {
        this.props.onAppSelected(
            this.data.softwareName as SoftwareName,
            connectionId,
            trigger
        );
    }
}
