import React from 'react';

import {SoftwareName} from '../../software';
import {EntityFormHelper, FieldError, UI} from '../../engrator-core';
import {NewConnectionData} from '../../connections';
import {DropdownOption} from '../../engrator-core/ui/dropdown';
import {AuthMethod} from './auth-method.type';
import {
    ConnectionFirstStepGuide,
    getNewConnectionFirstStepGuide,
    verifyConnectionUrl,
    VerifyUrlConnectionGuide
} from './rest-api';
import {FormError} from '../../engrator-core/form-error.type';
import {OAuthData, OAuthSupport} from './oauth/oauth-support';
import {registerUserActivityAction, UserActivityAction} from '../../app/system/user-activity';
import {ConnectionSetupVideo} from './connection-setup-video';
import {OAuth} from "./o-auth";
import {
    createConnection,
    editConnection,
    getShareAccessOptionsForConnection,
    ShareAccessOption
} from "../../software/generic/connections-rest-api";
import {getTrelloOAuth} from "../../software/trello/trello-software";
import {getSalesforceOauth} from "../../software/salesforce/salesforce-software";
import {ConnectionModel} from "../../app/integration/connections/connection-model";

export type ConnectionConfiguration = {
    requireUrl: boolean;
}

type Props = {
    softwareName: SoftwareName,
    supportedAuthMethods: AuthMethod[],
    onConnectionAddedHandler: () => void;
    oAuthSupport?: OAuthSupport
    config: ConnectionConfiguration;
    isEditMode?: boolean;
    connectionToEdit?: ConnectionModel;
};

enum NewConnectionStep {
    Url,
    Credentials
}

type State = {
    step: NewConnectionStep,
    formError: FormError,
    isSavingConnection: boolean,
    tempUrl: string,
    showPassword: boolean;
    connectionUpdated: boolean;
    urlVerification: {
        isLoading: boolean;
        success: boolean;
    },
    showLoginBox: boolean;
    showPasswordBox: boolean;
    firstStepGuide?: ConnectionFirstStepGuide;
    connectionGuide?: VerifyUrlConnectionGuide;
};


export class CommonConnectionComponent extends React.Component<Props, State> {
    private entityFormHelper: EntityFormHelper;
    private readonly newConnectionData: NewConnectionData;
    private authMethodOptions: DropdownOption[];

    constructor(props: Readonly<Props>) {
        super(props);
        const initialStep: NewConnectionStep = (this.props.config.requireUrl) ? NewConnectionStep.Url : NewConnectionStep.Credentials;
        const authMethod = (this.props.connectionToEdit?.authMethod as AuthMethod) || undefined;
        this.newConnectionData = {
            login: "",
            name: this.props.connectionToEdit?.name || `Connection to ${this.props.softwareName}`,
            password: "",
            softwareName: this.props.softwareName,
            url: this.props.connectionToEdit?.url || '',
            authCredentials: {},
            auth: {method: authMethod},
            authMethod
        };
        this.authMethodOptions = this.props.supportedAuthMethods.map(authMethod => ({
            value: authMethod,
            label: authMethod
        }));
        this.entityFormHelper = new EntityFormHelper((propertyName: 'url', newValue) => {
            this.newConnectionData[propertyName] = newValue;
        })
        this.state = {
            step: initialStep,
            formError: {},
            isSavingConnection: false,
            tempUrl: this.props.connectionToEdit?.url || '',
            showPassword: false,
            urlVerification: {
                success: false,
                isLoading: false
            },
            showLoginBox: true,
            showPasswordBox: true,
            connectionUpdated: false
        };
    }

    async componentDidMount() {
        // Select automatically authentication method if there is only one
        // Only if it is not connection edit mode
        if (!this.props.connectionToEdit && this.authMethodOptions.length === 1) {
            this.authMethodChanged(this.authMethodOptions[0].value as AuthMethod);
        }
        if (this.props.connectionToEdit) {
            this.newConnectionData.name = this.props.connectionToEdit.name;
            this.newConnectionData.shareAccess = this.props.connectionToEdit.shareAccess;
            this.newConnectionData.authCredentials['azureCollection'] = this.props.connectionToEdit?.azureCollection || this.state.connectionGuide?.additionalData?.azureCollection;
            await this.setState({showPasswordBox: false, showLoginBox: false});
        }
        getNewConnectionFirstStepGuide(this.props.softwareName)
            .then((firstStepGuide) => {
                // If we do not require URL, lets move forward
                let step = !firstStepGuide.showUrl ? NewConnectionStep.Credentials : NewConnectionStep.Url;
                // if (this.props.isEditMode) {
                //     step = NewConnectionStep.Credentials;
                // }
                // If its credentials step but not edit mode, lets verify url connectivity
                if (step === NewConnectionStep.Credentials) {
                    this.verifyUrl();
                } else {
                    this.setState({firstStepGuide, step});
                }
            })
            .catch((error) => {
                const formError = {'general': error.message};
                this.setState({formError});
            })
    }

    render() {
        const isConnectionError = /Temporary failure in name resolution|Connection timed out|Gateway Timeout|Service Unavailable|Bad Gateway|502|503|504|timeout|timed out/i.test(this.state.formError['general']);
        return <div>
            {/*<UI.FormSection label={`Create New Connection`}>*/}
            <UI.Form
                errors={[]}
            >
                <div className={`configure-connection connection-details-step`}>
                    <div className={`details`}>
                        {this.state.formError['general'] &&
                            <UI.Message
                                appearance={"error-message"}
                                message={this.state.formError['general'] + (isConnectionError ? " " : "")}
                                docLink={isConnectionError ? 'https://docs.getint.io/getintio-platform/connections/troubleshooting-getint-integration-connectivity-issues' : undefined}
                            />
                        }

                        {/*{ this.authMethodOptions.length > 1 && <UI.FormGroup*/}
                        {/*        isRequired={true}*/}
                        {/*        label={`Authentication Method`}>*/}
                        {/*        <UI.Dropdown options={ this.authMethodOptions } onChange={ this.authMethodChanged.bind(this) } />*/}
                        {/*    </UI.FormGroup> }*/}

                        {/*{ this.newConnectionData.authMethod && <UI.FormGroup*/}
                        {/*    label={`Name`}*/}
                        {/*    dataSel={'name'}*/}
                        {/*    error={ this.state.formError['name'] }*/}
                        {/*    description={`Name your connection to distinguish it on a list`}*/}
                        {/*    isRequired={true}>*/}
                        {/*    <UI.Input onChange={this.entityFormHelper.onChangeHandler('name')}/>*/}
                        {/*</UI.FormGroup>}*/}

                        {this.state.step === NewConnectionStep.Url && this.state.firstStepGuide && <React.Fragment>
                            {this.state.firstStepGuide.showUrl && <UI.FormGroup
                                dataSel={`url`}
                                label={`Url`}
                                error={this.state.formError['url']}
                                description={``}
                                isRequired={this.state.firstStepGuide.requireUrl}>
                                <UI.Message appearance={`info`}>Type url to your app instance and click NEXT. Typically
                                    its a url which you enter when you want to login to your
                                    app. {this.state.firstStepGuide.urlDescription}</UI.Message>
                                <UI.Input
                                    defaultValue={this.state.tempUrl}
                                    onChange={(newValue) => this.setState({tempUrl: newValue})}
                                />
                            </UI.FormGroup>}
                            <UI.Button
                                disabled={!this.state.tempUrl && this.state.firstStepGuide.requireUrl}
                                appearance={'secondary'}
                                onClick={() => this.verifyUrl()}
                                text={`Next`}
                                isLoading={this.state.urlVerification.isLoading}
                            />
                        </React.Fragment>}

                        {this.state.step === NewConnectionStep.Credentials && this.state.connectionGuide &&
                            <React.Fragment>
                                {this.newConnectionData.url && <UI.LabelValue
                                    label={`Url`}
                                    value={this.state.tempUrl}
                                />}
                                {/* Name */}
                                <UI.FormGroup
                                    label={`Name connection`}
                                    description={`Name connection to distinguish it on a  connections list`}
                                    dataSel={`name`}
                                >
                                    <UI.Input
                                        defaultValue={this.newConnectionData.name}
                                        onChange={(newValue) => this.newConnectionData.name = newValue}
                                    />
                                </UI.FormGroup>
                                {/* Username */}
                                {this.state.connectionGuide.additionalData?.azureServer && <UI.FormGroup
                                    label={`Collection Name`}
                                    description={`Provide a name of the collection to which you would like to connect`}
                                    dataSel={`collection`}
                                    isRequired={true}
                                >
                                    <UI.Input
                                        onChange={(newValue) => this.setCredential('azureCollection', newValue)}
                                        defaultValue={this.newConnectionData.authCredentials['azureCollection']}
                                    />
                                </UI.FormGroup>}
                                {/* Username */}
                                {this.state.connectionGuide.usernameLabel && <UI.FormGroup
                                    label={this.state.connectionGuide.usernameLabel}
                                    description={this.state.connectionGuide.usernameDescription}
                                    dataSel={`username`}
                                    isRequired={(this.state.connectionGuide.additionalData?.usernameRequired !== undefined) ? this.state.connectionGuide.additionalData?.usernameRequired : true}
                                >
                                    {this.state.showLoginBox && <UI.Input
                                        onChange={(newValue) => this.setCredential('login', newValue)}
                                    />}
                                    {!this.state.showLoginBox && <React.Fragment>
                                        <UI.Button
                                            appearance={'secondary'}
                                            text={`Edit ${this.state.connectionGuide.usernameLabel}`}
                                            onClick={() => this.setState({showLoginBox: true})}
                                        />
                                    </React.Fragment>}
                                </UI.FormGroup>}
                                {/* Password */}
                                {this.state.connectionGuide.passwordLabel && <UI.FormGroup
                                    label={this.state.connectionGuide.passwordLabel}
                                    isRequired={true}
                                    description={this.state.connectionGuide.passwordDescription}
                                    dataSel={`password`}
                                >
                                    {this.state.showPasswordBox && <React.Fragment>
                                        <UI.Input
                                            type={(this.state.showPassword) ? `text` : `password`}
                                            onChange={(newValue) => this.setCredential(this.state.connectionGuide!.passwordFieldId, newValue)}
                                        />
                                        <UI.Checkbox
                                            label={`Show ${this.state.connectionGuide.passwordLabel.toLowerCase()}`}
                                            onChange={() => this.setState({showPassword: !this.state.showPassword})}
                                            checkedValue={`true`} uncheckedValue={`false`}
                                        />
                                    </React.Fragment>}
                                    {!this.state.showPasswordBox && <React.Fragment>
                                        <UI.Button
                                            appearance={'secondary'}
                                            text={`Edit ${this.state.connectionGuide.passwordLabel}`}
                                            onClick={() => this.setState({showPasswordBox: true})}
                                        />
                                    </React.Fragment>}
                                </UI.FormGroup>}
                                { this.props.connectionToEdit && <UI.FormGroup
                                    label={`Team Access`}
                                    description={`Allow workspace members to view and collaborate on this connection`}
                                >
                                    <UI.Dropdown
                                        defaultValue={ this.newConnectionData.shareAccess }
                                        options={ getShareAccessOptionsForConnection() }
                                        onChange={ (newValue: ShareAccessOption) => this.changeShareAccess(newValue) }
                                    />
                                </UI.FormGroup> }
                                {/* OAuth button */}
                                {this.state.connectionGuide.authMethod === AuthMethod.OAuth && <React.Fragment>
                                    <OAuth
                                        auth={{data: {}, method: AuthMethod.OAuth}}
                                        formError={{}}
                                        app={this.props.softwareName}
                                        oAuthSupport={(this.props.softwareName === SoftwareName.Salesforce) ? getSalesforceOauth() : getTrelloOAuth()}
                                        onAuthorize={(data) => this.onAuthorizeHandler(data)}
                                        newConnectionData={this.newConnectionData}/>
                                </React.Fragment>}

                                {/* Add button */}
                                {!this.props.connectionToEdit && <UI.ButtonsBar
                                    primary={<UI.Button
                                        appearance="elementary"
                                        onClick={this.addConnection.bind(this)}
                                        isLoading={this.state.isSavingConnection} text="Add"
                                    />}
                                />}

                                {/* Edit button */}
                                {this.props.connectionToEdit && <UI.ButtonsBar
                                    primary={<UI.Button
                                        appearance="elementary"
                                        onClick={() => this.editConnection()}
                                        isLoading={this.state.isSavingConnection} text="Update"
                                    />}
                                    secondary={[
                                        this.state.connectionUpdated && <UI.Message appearance={'success'}>Connection updated successfully</UI.Message>
                                    ]}
                                />}
                            </React.Fragment>}
                    </div>
                    <div className={`info`}>
                        <ConnectionSetupVideo sofwareName={this.props.softwareName}/>
                    </div>
                </div>
            </UI.Form>
            {/*</UI.FormSection>*/}
        </div>
    }

    private onAuthorizeHandler(data: OAuthData): void {
        this.setCredential('token', data.token);
        this.setCredential('refreshToken', data.refreshToken);
        this.setCredential('instanceUrl', data.instanceUrl);
    }

    private verifyUrl() {
        this.setState({urlVerification: {success: false, isLoading: true}}, () => {
            verifyConnectionUrl(this.state.tempUrl, this.props.softwareName)
                .then((data) => {
                    if (!this.props.connectionToEdit) {
                        this.newConnectionData.authMethod = data.connectionGuide.authMethod;
                        this.newConnectionData.url = data.correctedUrl;
                    }
                    if (data.connectionGuide.additionalData) {
                        for (const key of Object.keys(data.connectionGuide.additionalData)) {
                            if (!this.newConnectionData.authCredentials[key]) {
                                this.newConnectionData.authCredentials[key] = data.connectionGuide.additionalData[key];
                            }
                        }
                    }
                    this.setState({
                        tempUrl: data.correctedUrl,
                        connectionGuide: data.connectionGuide,
                        urlVerification: {success: true, isLoading: false},
                        step: NewConnectionStep.Credentials
                    });
                })
                .catch((error) => {
                    const formError = {['general']: error.message};
                    this.setState({formError, urlVerification: {success: false, isLoading: false}});
                })
        });
    }

    private addConnection() {
        registerUserActivityAction(UserActivityAction.ClickedAddConnection);
        this.setState({formError: {}, isSavingConnection: true}, () => {
            createConnection(this.newConnectionData)
                .then(() => {
                    this.props.onConnectionAddedHandler();
                    registerUserActivityAction(UserActivityAction.CreatedConnection);
                }).catch((error: FieldError) => {
                const formError = {['general']: error.message};
                this.setState({isSavingConnection: false, formError});
            });
        });
    }

    private async editConnection() {
        try {
            await this.setState({ formError: {}, isSavingConnection: true, connectionUpdated: false });
            await editConnection(this.props.connectionToEdit!.id, this.newConnectionData)
            this.setState({ isSavingConnection: false, connectionUpdated: true, showLoginBox: false, showPasswordBox: false });
        } catch (error) {
            const formError = {['general']: error.message};
            this.setState({isSavingConnection: false, formError});
        }
    }

    private setCredential(field: string, value: string): void {
        if (field === 'azureCollection') {
            this.newConnectionData.authCredentials[field] = value;
        } else {
            this.newConnectionData.authCredentials[field] = value;
        }
    }

    private authMethodChanged(optionValue: AuthMethod) {
        this.newConnectionData.authMethod = optionValue;
        this.forceUpdate();
    }

    private openHelp() {
        (window as any).Tawk_API.maximize();
    }

    private changeShareAccess(option: ShareAccessOption) {
        this.newConnectionData.shareAccess = option;
        this.forceUpdate();
    }
}
