import { AppsSupport } from '../../visual-integration-designer';
import { SmartIntTrigger } from '../../../definition/smart-int-trigger.type';
import { SmartIntDefinitionTypeMapping } from '../../../definition/smart-int-definition--type-mapping.type';
import { SMART_INT_TYPE_CONTINUOUS_INTEGRATION_ID } from '../../../definition/smart-int-type.type';
import { CISupport, createCISupport } from '../../../ci/smartints-ci-support';

export function getSmartIntsCISupport(
    appsSupport: AppsSupport,
    leftTrigger: SmartIntTrigger,
    rightTrigger: SmartIntTrigger,
    mapping: SmartIntDefinitionTypeMapping): CISupport {
    // get opposite side CI support, so when its GITLAB repo with Jira issues,
    // we want to take JIRA CI SUpport to get Jira statuses and pattern
    if (mapping.right.id === SMART_INT_TYPE_CONTINUOUS_INTEGRATION_ID) {
        return createCISupport(appsSupport.leftApp, leftTrigger);
    }
    return createCISupport(appsSupport.rightApp, rightTrigger);
}
