import React, {Component} from "react";

import {UI} from 'src/engrator-core';

type Props = {
    createHandler: (name: string, value: string) => void;
};

type State = {
    header: {
        name: string;
        value: string;
    }
};


export class NotificationsWebhooksHeader extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            header: {
                name: '',
                value: ''
            }
        };
    }

    render() {
        return (
            <div className={`flex row two-columns add-header`}>
                <div>
                    <UI.Input
                        onChange={(value) => this.setName(value)}
                        label={`Header name`}
                        placeholder={`Header name`}
                        defaultValue={ this.state.header.name }
                    />
                </div>
                <div>
                    <UI.Input
                        onChange={(value) => this.setValue(value)}
                        label={`Header value`}
                        placeholder={`Header value`}
                        defaultValue={ this.state.header.value }
                    />
                </div>
                <div className={`btn`}>
                    <UI.Button
                        disabled={!this.state.header.name || !this.state.header.value}
                        onClick={() => this.addClicked()}
                        text={`Add`}
                    />
                </div>
            </div>
        );
    }

    private setName(value: string) {
        const header = this.state.header;
        header.name = value;
        this.setState({header});
    }

    private setValue(value: string) {
        const header = this.state.header;
        header.value = value;
        this.setState({header});
    }

    private addClicked() {
        this.props.createHandler(this.state.header.name, this.state.header.value);
        const header = this.state.header;
        header.name = '';
        header.value = '';
        this.setState({ header });
    }
}