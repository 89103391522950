import {VerticalMenu} from "../global-store/create-global-state";

export const workflowsVerticalMenu: VerticalMenu = {
    header: 'Workflows',
    items: [{
        name: 'Integrations',
        path: '/app/integration/smart-ints'
    }, {
        name: 'Connections',
        path: '/app/integration/connections'
    }, {
        name: 'Notifications',
        path: '/app/integration/notifications'
    }]
};