import { axiosWrapper } from 'src/engrator-core';
import { SoftwareName } from '../../../../software';
import { SmartIntTrigger } from '../definition/smart-int-trigger.type';
import { Status } from '../../../../software/generic/status-transition/status.type';

const ENDPOINT_URL = '/integration/smart-ints/ci';

export const fetchStatuses = (appName: SoftwareName, trigger: SmartIntTrigger): Promise<Status[]> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + '/' + appName + '/statuses', trigger)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchPattern = (appName: SoftwareName, trigger: SmartIntTrigger): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + '/' + appName + '/related-item-pattern', trigger)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
