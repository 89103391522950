import React from 'react';
import {
    createEmptyFilters,
    fetchIntegrationsGroups,
    IntegrationFilters,
    isAnyFilterUsed,
    MAIN_GROUP_NAME
} from './rest-api';
import {DropdownFilter, InputFilter} from "../../../../reporting/filters";
import {DropdownOption} from "../../../../reporting/filters/dropdown-option.type";
import {EntityStatusValue} from "../../../../../engrator-core/ui";
import { UI } from 'src/engrator-core';

type Props = {
    onFiltersChanged: (newFiltersValues: IntegrationFilters) => void;
    defaultFilters: IntegrationFilters;
};

type State = {
    groups?: string[];
    filters: IntegrationFilters;
};

const STATUSES: DropdownOption[] = [{
    label: 'Disabled',
    value: 'Disabled',
    isSelected: false
}, {
    label: 'Enabled',
    value: 'Enabled',
    isSelected: false
}];

export class IntegrationsListFiltering extends React.Component<Props, State> {
    private nameTimeout?: NodeJS.Timeout;
    constructor(props: Props) {
        super(props);
        this.state = {
            filters: this.props.defaultFilters
        };
    }

    componentDidMount() {
        fetchIntegrationsGroups()
            .then(groups => {
                this.setState({groups});
            })
            .catch((error) => {

            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (JSON.stringify(this.props.defaultFilters) !== JSON.stringify(prevProps.defaultFilters)) {
            this.setState({ filters: this.props.defaultFilters });
        }
    }

    render() {
        if (!this.state.groups || this.state.groups.length === 1) {
            return <React.Fragment/>
        }
        return <React.Fragment>
            <InputFilter
                filterChangeHandler={ (filter) => this.onNameFilterChanged(filter['query']) }
                title={`Search by name`}
                placeholder={`Search by name`}
                defaultValue={ this.state.filters.query }
            />
            <DropdownFilter
                isSingleSelect={true}
                defaultValues={{ selectedValues: this.state.filters.selectedGroupName }}
                title={`Group`}
                options={ this.state.groups.map(g => ({
                    value: g,
                    label: g,
                    isSelected: false
                }))}
                listenForChanges={ true }
                filterChangeHandler={(filter) => this.onSelectedHandler(filter['selectedValues'])}
            />
            <DropdownFilter
                isSingleSelect={ true }
                filterChangeHandler={(filter) => this.onStatusFilterChanged(filter['selectedValues'][0])}
                title={`Status`}
                options={ STATUSES }
                listenForChanges={ true }
                defaultValues={{ selectedValues: (this.state.filters.selectedStatus) ? [this.state.filters.selectedStatus] : [] }}
            />
            { isAnyFilterUsed(this.state.filters) && <UI.Button
                text={`Clear`}
                appearance={"link-inline"}
                onClick={ () => this.clearFilters() }
            /> }
        </React.Fragment>
    }

    private async clearFilters() {
        await this.setState({ filters: createEmptyFilters() });
        this.props.onFiltersChanged(this.state.filters);
    }

    private async onSelectedHandler(groupName: string) {
        const filters = this.state.filters;
        filters.selectedGroupName = groupName;
        await this.setState({ filters })
        this.props.onFiltersChanged(filters);
    }

    private async onStatusFilterChanged(newStatus?: EntityStatusValue) {
        const filters = this.state.filters;
        filters.selectedStatus = newStatus;
        await this.setState({ filters })
        this.props.onFiltersChanged(filters);
    }

    private async onNameFilterChanged(query: string) {
        if (this.nameTimeout) {
            clearInterval(this.nameTimeout);
        }
        this.nameTimeout = setTimeout(() => {
            const filters = this.state.filters;
            filters.query = query;
            this.setState({ filters }, () => {
                this.props.onFiltersChanged(filters);
            });
        }, 1000);
    }
}
