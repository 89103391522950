import React from 'react';
import {SoftwareLogo} from 'src/designer/step-creator/software-logo';

import {FieldError, UI} from 'src/engrator-core';
import {RightPanelContent} from '../../../../engrator-core/ui/right-panel-content';
import {AuthMethod} from '../../../../generic/connections/auth-method.type';
import {ConnectionModel, ConnectionUpdateData} from '../connection-model';
import {testConnection, updateConnection} from '../rest-api';
import {BearerToken} from "../../../../generic/connections/bearer-token";
import {SoftwareName} from "../../../../software";
import {FeatureFlag, isFeatureFlagEnabled} from "../../../top-navigation/rest-api";
import softwareFactory from "../../../../software/software-factory";

const SUCCESSFUL_TEST_MESSAGE = 'Connection test succeeded';
const SUCCESSFUL_UPDATE_MESSAGE = 'Connection update succeeded';

type ConnectionEditProps = {
    connection: ConnectionModel;
    closeHandler: () => void;
};

type ConnectionEditState = {
    isLoading: boolean,
    errors: Array<FieldError>,
    successMessage: string;
    formError: { [key: string]: string };
    showDeleteModal: boolean;
}

type JiraDatabase = {
    jdbc: string;
    username: string;
    password: string;
    schemaName: string;
}

export class ConnectionEdit extends React.Component<ConnectionEditProps, ConnectionEditState> {
    connection: ConnectionUpdateData = {
        login: '',
        password: '',
        name: '',
        customConfiguration: {}
    };
    private connectionFactory: any;

    constructor(props: ConnectionEditProps) {
        super(props);
        this.connectionFactory = softwareFactory.getSoftwareByName(this.props.connection.softwareName)?.getConnectionFactory();
        this.setDefaults();
    }

    componentDidMount() {
    }

    setDefaults() {
        this.connection = Object.assign({
            login: this.props.connection.login,
            name: this.props.connection.name
        });
        this.state = { showDeleteModal: false, isLoading: false, errors: [], formError: {}, successMessage: '' };
    }

    componentDidUpdate(prevProps: ConnectionEditProps) {
        if (this.props.connection.id !== prevProps.connection.id) {
            this.setDefaults();
        }
    }

    onUpdateClick(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.props.connection.authMethod === AuthMethod.BearerToken && this.props.connection.authData['token'] !== '******') {
                this.connection.password = this.props.connection.authData['token'];
            }
            this.performApiCall(updateConnection(this.props.connection.id, this.connection), SUCCESSFUL_UPDATE_MESSAGE)
                .then(() => resolve(true))
                .catch(() => resolve(false))
        });
    }

    onTestClick() {
        if (this.props.connection.authMethod === AuthMethod.BearerToken && this.props.connection.authData['token'] !== '******') {
            this.connection.password = this.props.connection.authData['token'];
        }
        this.performApiCall(testConnection(this.props.connection.id, this.connection), SUCCESSFUL_TEST_MESSAGE);
        document.querySelector('.fullscreen-modal .inner .box')?.scrollTo(0,0);
    }

    changeProperty(propertyName: 'login' | 'password' | 'name', newValue: string) {
        this.connection[propertyName]= newValue;
    }

    performApiCall(fnToExecute: any, successMessage: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true, errors: [], formError: {}, successMessage: ''});
            fnToExecute.then(() => {
                this.setState({isLoading: false, errors: [], successMessage: successMessage});
                resolve(true);
            })
            .catch((error: any) => {
                const formError = {[error.fieldName]: error.message};
                this.setState({isLoading: false, errors: [error], formError});
                resolve(false);
            });
        });
    }

    render() {
        const FooterButtons = <UI.ButtonsBar
                primary={ <UI.Button isLoading={ this.state.isLoading } text="Update" onClick={() => this.onUpdateClick() } /> }
                secondary={ [
                    <UI.Button isLoading={ this.state.isLoading } appearance="secondary" text="Test" onClick={ () => this.onTestClick() } />
                ] } ></UI.ButtonsBar>;
        return <React.Fragment>
            <UI.FullScreenModal
                hideFooter={true}
                header={`Edit Connection #${ this.props.connection.id }`}
                primaryBtnText={`Update`}
                primaryBtnHandler={() => this.onUpdateClick()}
                closeBtnHandler={ () => this.props.closeHandler() }
                hideCloseBtn={ true }
                maximized={ true }
                secondaryBtn={ <UI.Button isLoading={ this.state.isLoading } appearance="secondary" text="Test" onClick={ () => this.onTestClick() } /> }
                // <React.Fragment>
                // </React.Fragment>
            // footer={ FooterButtons }
            >
                <React.Fragment>
                    <UI.Message appearance={"info"}>
                        <span>Getint uses REST API to communicate with the applications. Use a user with the needed rights, to enable Getint to perform actions (e.g. create, update).</span>
                    </UI.Message>
                    { this.connectionFactory.getConnectionComponent(() => {}, this.props.connection) }
                </React.Fragment>

                {/*<UI.Form errors={ [] }  error={ this.state.formError['general'] }>*/}
                {/*    { this.state.successMessage && <UI.Message appearance={"success"}>{ this.state.successMessage }</UI.Message> }*/}
                {/*    <UI.FormGroup>*/}
                {/*        <UI.LabelValue label="Software" value={ this.props.connection.softwareName } />*/}
                {/*    </UI.FormGroup>*/}
                {/*    <UI.FormGroup>*/}
                {/*        <UI.LabelValue label="Url"*/}
                {/*                       value={<a target={`blank`} href={this.props.connection.url}>{this.props.connection.url}</a>}/>*/}
                {/*    </UI.FormGroup>*/}
                {/*    <UI.FormSection*/}
                {/*        label={`Details`}*/}
                {/*    >*/}
                {/*        <UI.FormGroup*/}
                {/*            label={`Name`}*/}
                {/*            error={ this.state.formError['name'] }*/}
                {/*            description={`Meaningful name`}*/}
                {/*            isRequired={true}>*/}
                {/*            <UI.Input defaultValue={ this.props.connection.name } onChange={ (newValue: string) => { this.changeProperty('name', newValue) }   }/>*/}
                {/*        </UI.FormGroup>*/}
                {/*        <UI.FormGroup>*/}
                {/*            <UI.LabelValue label="Auth Method" value={ this.props.connection.authMethod } />*/}
                {/*        </UI.FormGroup>*/}
                {/*        { this.props.connection.authMethod === AuthMethod.BasicAuth && <React.Fragment>*/}
                {/*            <UI.FormGroup*/}
                {/*                label={`Login`}*/}
                {/*                error={ this.state.formError['login'] }*/}
                {/*                description={`Username/Email used to login to ${this.props.connection.softwareName}`}*/}
                {/*                isRequired={true}>*/}
                {/*                <UI.Input defaultValue={ this.connection.login } onChange={ (newValue: string) => { this.changeProperty('login', newValue) }   }/>*/}
                {/*            </UI.FormGroup>*/}
                {/*            <UI.FormGroup*/}
                {/*                label={`Password`}*/}
                {/*                error={ this.state.formError['password'] }*/}
                {/*                isRequired={true}>*/}
                {/*                <UI.Input type="password" onChange={ (newValue: string) => { this.changeProperty('password', newValue) }   }/>*/}
                {/*            </UI.FormGroup>*/}
                {/*        </React.Fragment> }*/}
                {/*        { this.props.connection.authMethod === AuthMethod.BearerToken && <React.Fragment>*/}
                {/*            <BearerToken formError={this.state.formError} auth={this.props.connection.authData!}/>*/}
                {/*        </React.Fragment> }*/}

                {/*        /!*{ isFeatureFlagEnabled(FeatureFlag.FF_ENABLE_JIRA_DB_SYNC) && this.props.connection.softwareName === SoftwareName.Jira && <UI.FormGroup*!/*/}
                {/*        /!*    label={`Connect directly with Jira Database`}*!/*/}
                {/*        /!*    description={`Fetch issues data much faster by direct connection to Jira Database`}*!/*/}
                {/*        /!*>*!/*/}
                {/*        /!*    <UI.Input*!/*/}
                {/*        /!*        isRequired={ true }*!/*/}
                {/*        /!*        label={`JDBC`}*!/*/}
                {/*        /!*        defaultValue={ this.props.connection.customConfiguration?.database?.jdbc }*!/*/}
                {/*        /!*        onChange={ (newValue) => this.changeDatabaseProperty('jdbc', newValue) }*!/*/}
                {/*        /!*    />*!/*/}
                {/*        /!*    <UI.Input*!/*/}
                {/*        /!*        label={`Database username`}*!/*/}
                {/*        /!*        defaultValue={ this.props.connection.customConfiguration?.database?.username }*!/*/}
                {/*        /!*        onChange={ (newValue) => this.changeDatabaseProperty('username', newValue) }*!/*/}
                {/*        /!*    />*!/*/}
                {/*        /!*    <UI.Input*!/*/}
                {/*        /!*        type={ 'password' }*!/*/}
                {/*        /!*        label={`Database password`}*!/*/}
                {/*        /!*        defaultValue={ this.props.connection.customConfiguration?.database?.password }*!/*/}
                {/*        /!*        onChange={ (newValue) => this.changeDatabaseProperty('password', newValue) }*!/*/}
                {/*        /!*    />*!/*/}
                {/*        /!*    <UI.Input*!/*/}
                {/*        /!*        type={ 'text' }*!/*/}
                {/*        /!*        label={`Schema name`}*!/*/}
                {/*        /!*        defaultValue={ this.props.connection.customConfiguration?.database?.schemaName }*!/*/}
                {/*        /!*        onChange={ (newValue) => this.changeDatabaseProperty('schemaName', newValue) }*!/*/}
                {/*        /!*    />*!/*/}
                {/*        /!*</UI.FormGroup> }*!/*/}
                {/*    </UI.FormSection>*/}
                {/*</UI.Form>*/}
            </UI.FullScreenModal>
        </React.Fragment>
    }


    private changeDatabaseProperty(property: keyof JiraDatabase, newValue: string) {
        if (!this.connection.customConfiguration) {
            this.connection.customConfiguration = { database: {} };
        }
        let database: JiraDatabase = this.connection.customConfiguration.database;
        if (!database) {
            database =  {
                jdbc: '',
                username: '',
                password: '',
                schemaName: ''
            };
        }
        database[property] = newValue;
        this.connection.customConfiguration.database = database;
    }

}
