import React from 'react';

import './table.css';
import {FieldError} from "../field-error";
import {Message} from "./message";
import { UI } from '..';


type TableProps = {
    dataSource: () => Promise<any>,
    rowDecorator: any,
    shouldReload?: boolean,
    shouldReloadListener?: (() => boolean);
    emptyTableHolder?: any;
    onBulkSelect?: (rows: any[]) => void;
};

type TableState = {
    headers: Array<string>,
    data: Array<Array<any>>,
    error?: FieldError,
    isLoading: boolean;
}

export class Table extends React.Component<TableProps, TableState> {
    private selectedRows: any[] = [];

    constructor(props: TableProps) {
        super(props);
        this.state = {
            headers: [],
            data: [],
            error: undefined,
            isLoading: false
        };
    }

    componentDidMount() {
        this.loadDataSource();
    }

    componentDidUpdate(prevProps: TableProps) {
        if (this.props.shouldReload !== undefined && this.props.shouldReload !== prevProps.shouldReload) {
            this.loadDataSource();
        }
    }

    render() {
        if (this.state.error) {
            return <Message appearance={"error"}>{ this.state.error?.message }</Message>
        }
        if (this.state.headers.length > 0 && this.state.data.length === 0 && this.props.emptyTableHolder) {
            return this.props.emptyTableHolder;
        }
        if (this.state.isLoading) {
            return <UI.CenteredLoader text={`Loading...`} />;
        }
        return <React.Fragment>
            { this.state.headers && 
                <table className="engrator-table" cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            { this.state.headers && this.state.headers.map((header: string, index: number) => <th key={ index }>
                                { header }
                            </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.data && this.state.data.map((row: Array<any>, index: number) => <tr key={ index }>
                            { this.props.onBulkSelect && <td key={-1}>
                                <UI.Checkbox checkedValue="true" uncheckedValue="false" onChange={() => 
                                    this.rowItemSelected(row)
                                } />
                            </td> }
                            { this.props.rowDecorator(row).map((cell: any, cellIndex: number) => <td key={ cellIndex }>
                                { cell }
                            </td> )}
                        </tr>)}
                    </tbody>
                </table>
            }
        </React.Fragment>;
    }

    private loadDataSource() {
        if (this.props.dataSource) {
            this.selectedRows = [];
            this.setState({ isLoading: true, headers: [], data: [] }, () => {
                this.props.dataSource().then(({data, headers}) => {
                    if (this.props.onBulkSelect) {
                        headers.unshift('');
                    }
                    this.setState({ isLoading: false, headers, data, error: undefined});
                }).catch(error => {
                    this.setState({ isLoading: false, error });
                });
            });
        }
    }

    private rowItemSelected(row: any) {
        const index = this.selectedRows.indexOf(row);
        if (index >= 0) {
            this.selectedRows.splice(index, 1);
        } else {
            this.selectedRows.push(row);
        }
        console.log(this.selectedRows);
        this.props.onBulkSelect!(this.selectedRows);
    }
}