import React from 'react';
import { SoftwareLogo } from '../../../../../../designer/step-creator/software-logo';
import { SoftwareLogotype } from '../../../../../../designer/step-creator/software-logotype';
import {ImportValidateResult} from "./rest-api";
import { UI } from 'src/engrator-core';

type Props = {
    validationResult: ImportValidateResult;
    connectionIdSelectedHandler: (side: 'left' | 'right', connectionId: number) => void;

};

type State = {
};

export class IntegrationImportConnections extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <React.Fragment>
            <UI.Message appearance={'info'}>Because you are importing integration most probably from other getint.io instance,
            you need to specify connections that will be used to communicate with { this.props.validationResult.leftSoftwareName } and { this.props.validationResult.rightSoftwareName }
            </UI.Message>
            <div className={`flex row two-columns`}>
                <div className={`left`}>
                    <SoftwareLogotype softwareName={ this.props.validationResult.leftSoftwareName } />
                    <UI.FormGroup
                        label={`Select connection`}
                        isRequired={ true }
                    >
                        <UI.Dropdown
                            options={ this.props.validationResult.leftConnections }
                            onChange={ (value: any) => this.connectionChanged('left', value) }
                        />
                    </UI.FormGroup>
                </div>
                <div className={`right`}>
                    <SoftwareLogotype softwareName={ this.props.validationResult.rightSoftwareName } />
                    <UI.Dropdown
                        label={`Select connection`}
                        isRequired={ true }
                        options={ this.props.validationResult.rightConnections }
                        onChange={ (value: any) => this.connectionChanged('right', value) }
                    />
                </div>
            </div>
        </React.Fragment>
    }

    private connectionChanged(side: 'left' | 'right', value: any): void {
        this.props.connectionIdSelectedHandler(side, value);
    }
}
