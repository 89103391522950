import React from 'react';
import { ISoftwareFactory, SoftwareName } from '../../../../../software';
import { UI } from 'src/engrator-core';
import { SmartIntTrigger } from '../../definition/smart-int-trigger.type';
import { Connection } from '../../../../../connections';
import { DropdownOption, RightPanelContent } from 'src/engrator-core/ui';
import { validateTrigger } from '../rest-api';
import { AppSmartIntsSupport } from '../../app-smart-ints-support';
import RightPanel from 'src/designer/container/right-panel/RightPanel';
import { SoftwareLogo } from '../../../../../designer/step-creator/software-logo';
import {getTrailData} from "../../../../engagement/rest-api";
import {ConnectionSetupVideo} from "../../../../../generic/connections/connection-setup-video";

type State = {
    connectionsOptions?: DropdownOption[];
    showCreateConnection: boolean;
    isLoading: boolean;
    isLoadingConnections: boolean;
    showNewConnectionForm: boolean;
    isConfigured: boolean;
};

type Props = {
    softwareName: SoftwareName;
    onConnectionSetUp: (connectionId: number, trigger: SmartIntTrigger) => void;
    softwareFactory: ISoftwareFactory;
    connectionId?: number;
    disableGuide?: boolean;
}

export class SelectConnectionDetailsStep extends React.Component<Props, State> {
    private smartIntsSupport: AppSmartIntsSupport;
    private connectionFactory: any;
    private pickedConnection: Connection | undefined;
    private configuration: SmartIntTrigger | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoadingConnections: false,
            isLoading: false,
            showCreateConnection: false,
            isConfigured: false,
            showNewConnectionForm: false
        };
        const software = this.props.softwareFactory.getSoftwareByName(this.props.softwareName)!;
        this.smartIntsSupport = software.getSmartIntsSupport()!;
        this.connectionFactory = software.getConnectionFactory();
    }

    componentDidMount() {
        this.fetchConnections();
    }

    private fetchConnections() {
        if (this.props.connectionId) {
            this.setConnection('', { 
                id: this.props.connectionId,
                login: '',
                url: '',
                name: '',
                softwareName: SoftwareName.Jira
            });
            return;
        }
        this.setState({ isLoadingConnections: true }, () => {
            this.connectionFactory.fetchConnections()
                .then((connections: Connection[]) => {
                    const connectionsOptions: DropdownOption[] = connections.map((connection: any) => ({
                        value: String(connection.id),
                        label: connection.name + ` ${(connection.available === false) ? '(forbidden)' : ''}`,
                        object: connection,
                        available: connection.available
                    }));
                    this.setState({
                        showNewConnectionForm: connectionsOptions.length === 0,
                        connectionsOptions,
                        isLoadingConnections: false
                    });
                });
        });
    }


    render() {
        if (!this.pickedConnection) {
            return <div className={`connection-details-step`}>
                <UI.Message appearance={"info"}>
                    <span>Getint uses REST API to communicate with the applications. Use a user with the needed rights, to enable Getint to perform actions (e.g. create, update).</span>
                </UI.Message>
                { this.state.isLoadingConnections && <UI.Loader visible={true} appearance={"darkgray"} /> }
                { !!this.state.connectionsOptions && <span className={`loaded`}>&nbsp;</span> }
                { !this.state.showNewConnectionForm &&  !!this.state.connectionsOptions && !this.state.isLoadingConnections && <div className={`configure-connection`}>
                    <UI.Form>
                        <UI.FormGroup
                            label={`Select connection`}
                            description={``}
                            isRequired={true}
                        >
                            {/*{ this.state.connectionsOptions && this.state.connectionsOptions.length === 0 && <UI.Message */}
                            {/*    appearance='info'*/}
                            {/*>No connections found.</UI.Message> }*/}
                            { !this.state.connectionsOptions && <UI.Loader visible={true} appearance={"darkgray"} /> }
                            { this.state.connectionsOptions && this.state.connectionsOptions.length > 0 && <React.Fragment>
                                <UI.Dropdown
                                    options={this.state.connectionsOptions}
                                    onChange={this.setConnection.bind(this)}
                                />
                            </React.Fragment> }

                            <br/>
                            { this.props.softwareName !== SoftwareName.GetintArchiver && !this.state.showNewConnectionForm && <UI.Button
                                className={`create-new-btn`}
                                onClick={() => this.createConnectionClicked() }
                                appearance={'secondary'} text={`Create New Connection`}
                            /> }

                            {/*    </RightPanelContent>*/}
                            {/*</RightPanel> }*/}
                        </UI.FormGroup>
                    </UI.Form>
                    <div className={`info`}>
                        <ConnectionSetupVideo sofwareName={this.props.softwareName}/>
                    </div>
                </div> }
                { this.state.showNewConnectionForm && <React.Fragment>
                    { this.connectionFactory.getConnectionComponent(() => this.onConnectionAddedHandler() ) }
                </React.Fragment> }
            </div>;
        }
        if (this.pickedConnection) {
            const isConfigured = (this.configuration?.configuration) && !this.state.isConfigured;
            return <div className={`connection-details-step configure-connection`}>
                <UI.Form>
                    {this.pickedConnection!.name && <UI.Message appearance={"info"}>
                        Specify further settings, to e.g. narrow down the scope to a given project, or query
                    </UI.Message>  }
                        <UI.LabelValue
                            label={`Connection`}
                            value={this.pickedConnection!.name}
                        />
                    {this.smartIntsSupport.getTriggerConfigurationComponent(
                        this.pickedConnection!.id,
                        (configuration: SmartIntTrigger) => this.onTriggerConfigured(configuration)
                    )}

                    <UI.ButtonsBar primary={<UI.Button
                        disabled={ !isConfigured }
                        onClick={this.done.bind(this)}
                        isLoading={this.state.isLoading}
                        text={`Connect`}
                    />}/>
                </UI.Form>
                { !this.props.disableGuide && <div className={`info`}>
                    <ConnectionSetupVideo sofwareName={this.props.softwareName}/>
                </div> }
            </div>
        }
        return <React.Fragment></React.Fragment>;
    }

    private onConnectionAddedHandler() {
        this.setState({ showNewConnectionForm: false}, () => {
            this.fetchConnections();
        });
    }
    private onTriggerConfigured(configuration: SmartIntTrigger) {
        this.configuration = configuration;
        this.forceUpdate();
    }

    private setConnection(value: string, object?: Connection) {
        this.pickedConnection = object;
        if (!this.smartIntsSupport.hasTriggerConfigurationComponent()) {
            this.configuration = {
                app: this.props.softwareName,
                connectionId: this.pickedConnection!.id,
                artifactName: this.props.softwareName.toLocaleLowerCase() + 'Item',
                configuration: undefined,
                className: ''
            }
        }
        this.forceUpdate();
    }

    private createConnectionClicked() {
        // Attach smartlook to record sessions only if this is saas
        getTrailData()
            .then(data => {
                if (data.isSaas || data.saas) {
                    (window as any)['addSmartLook']();
                }
            });
        this.setState({showNewConnectionForm: true})
    }

    private done() {
        if (this.pickedConnection && this.configuration) {
            this.setState({ isLoading: true }, () => {
                validateTrigger(this.props.softwareName, this.configuration!).then(() => {
                    this.props.onConnectionSetUp(
                        this.pickedConnection!.id,
                        this.configuration!
                    );
                    this.setState({ isLoading: false, isConfigured: true });
                }).catch(() => {
                    this.setState({ isLoading: false });
                });
            });
        }
    }
}
