import React from 'react';

type Props = {
    status: 'Disabled' | 'Enabled' | 'OK'
};

export enum EntityStatusValue {
    'Enabled' = 'Enabled',
    'Disabled' = 'Disabled'
}

export class EntityStatus extends React.Component<Props> {
    render() {
        return <span className={`engrator-ui-entity-status ${this.getClassName()}`}>
            {this.props.status}
        </span>;
    }

    getClassName(): string {
        if (this.props.status === 'OK') {
            return 'Enabled'.toLocaleLowerCase();
        }
        return this.props.status.toLocaleLowerCase();
    }
}
