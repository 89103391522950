import React from 'react';
import {TypeBox} from './type-box';
import {TypeMappingConfiguration} from '../type-mapping-configuration';
import {AppsSupport} from '../visual-integration-designer';
import {SmartIntTrigger} from '../../definition/smart-int-trigger.type';
import {
    isCISmartIntTypeMapping,
    SmartIntDefinitionTypeMapping
} from '../../definition/smart-int-definition--type-mapping.type';
import {CITypeMappingConfiguration} from '../type-mapping-configuration/ci/ci-type-mapping.configuration';
import {UI} from 'src/engrator-core';
import {TypeMappingContextMenu} from './context-menu';
import {ContextMenuItem, Icon20} from 'src/engrator-core/ui';
import {getCopiedConfiguration, TypeMappingContextMenuItemsIds} from './context-menu/context-menu-item';
import {getTypeMappingWarning} from '../../configuration/configuration-warning-checker';
import {WarningFlag} from '../../configuration/warning-flag';

type TypeBoxState = {
    showConfiguration: boolean;
};

type TypeBoxProps = {
    isBeingAutoMapped?: boolean;
    support: AppsSupport;
    mapping: SmartIntDefinitionTypeMapping;
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
    multiFieldsUsage: boolean;
    deleteMapping: () => void;
    isFirst: boolean;
    isLast: boolean;
    changeOrderHandler: (direction: 1 | -1) => void;
}

export class TypesMapping extends React.Component<TypeBoxProps, TypeBoxState> {
    private mapping: SmartIntDefinitionTypeMapping;

    constructor(props: TypeBoxProps) {
        super(props);
        this.state = { showConfiguration: false };
        this.mapping = JSON.parse(JSON.stringify(this.props.mapping));
    }

    componentDidUpdate(prevProps: Readonly<TypeBoxProps>, prevState: Readonly<TypeBoxState>, snapshot?: any) {
        if (this.props.isFirst !== prevProps.isFirst ||
            this.props.isLast !== prevProps.isLast) {
            this.forceUpdate();
        } else if (this.props.isBeingAutoMapped !== prevProps.isBeingAutoMapped) {
            this.forceUpdate();
        }
    }

    render() {
        const isCiTypeMapping = isCISmartIntTypeMapping(this.props.mapping);
        const warning = getTypeMappingWarning(this.props.mapping);
        return <div className={`types-mapping status-${this.props.mapping.status} ${ (this.props.isBeingAutoMapped) ? 'is-automapped' : ''}`}>
            { this.props.isBeingAutoMapped && <div className={`automapping-in-progress`}>
                <UI.CenteredLoader text={``} />
            </div> }
            <div className={`type-boxes`} onClick={() => this.showTypeMappingConfiguration(true)}>
                <TypeBox name={this.props.mapping.left.name} className={`left`}/>
                <TypeBox name={this.props.mapping.right.name} className={`right`}/>
                {!isCiTypeMapping && <div className={`type-configs`}>
                    {this.props.mapping.commentsConfiguration.enabled && <span className={`label`}><UI.Icon icon20={ Icon20.Comments } /></span>}
                    {this.props.mapping.attachmentsConfiguration.enabled && <span className={`label`}><UI.Icon icon20={ Icon20.Paperclip } /></span>}
                    <span className={`label`}>{this.props.mapping.propertiesConfiguration.properties.length} FIELDS</span>
                </div>}
                { !this.props.isBeingAutoMapped && <UI.Icon icon="edit"/> }
                <hr/>
            </div>
            {/* <img className={`cog`} src={cogIcon} onClick={() => this.showTypeMappingConfiguration(true)}/> */}
            { !this.props.isBeingAutoMapped && <TypeMappingContextMenu
                mapping={ this.props.mapping }
                isFirst={ this.props.isFirst }
                isLast={ this.props.isLast }
                onConfigurationCopied={ this.onConfigurationCopied.bind(this) }
                onSelected={ (item: ContextMenuItem) => this.contextMenuItemSelected(item) }
            /> }
            { warning && <WarningFlag warning={ warning } /> }
            {this.state.showConfiguration && <React.Fragment>
                {!isCiTypeMapping && <TypeMappingConfiguration
                    mapping={this.mapping}
                    appsSupport={this.props.support}
                    leftTrigger={this.props.leftTrigger}
                    rightTrigger={this.props.rightTrigger}
                    multiFieldsUsage={ this.props.multiFieldsUsage }
                    deleteHandler={() => this.deleteMapping()}
                    closeHandler={() => this.showTypeMappingConfiguration(false)}
                    doneHandler={this.updateMapping.bind(this)}
                />}
                {isCiTypeMapping && <CITypeMappingConfiguration
                    mapping={this.mapping}
                    appsSupport={this.props.support}
                    leftTrigger={this.props.leftTrigger}
                    rightTrigger={this.props.rightTrigger}
                    deleteHandler={() => this.deleteMapping()}
                    closeHandler={() => this.showTypeMappingConfiguration(false)}
                    doneHandler={this.updateMapping.bind(this)}
                />}
            </React.Fragment>}
        </div>
    }

    private contextMenuItemSelected(item: ContextMenuItem) {
        if (item.id === TypeMappingContextMenuItemsIds.EDIT) {
            this.setState({ showConfiguration: true });
        } else if (item.id === TypeMappingContextMenuItemsIds.USE_CONFIGURATION) {
            const copiedConfiguration = getCopiedConfiguration();
            if (copiedConfiguration) {
                copiedConfiguration.left = this.props.mapping.left;
                copiedConfiguration.right = this.props.mapping.right;
                this.updateMapping(copiedConfiguration);
            }
        } else if (item.id === TypeMappingContextMenuItemsIds.DISABLE) {
            this.props.mapping.status = 'Disabled';
            this.forceUpdate();
        } else if (item.id === TypeMappingContextMenuItemsIds.ENABLE) {
            this.props.mapping.status = 'Enabled';
            this.forceUpdate();
        } else if (item.id === TypeMappingContextMenuItemsIds.DELETE) {
            this.deleteMapping();
            this.forceUpdate();
        } else if (item.id === TypeMappingContextMenuItemsIds.MOVE_UP) {
            this.props.changeOrderHandler(-1);
            this.forceUpdate();
        } else if (item.id === TypeMappingContextMenuItemsIds.MOVE_DOWN) {
            this.props.changeOrderHandler(1);
            this.forceUpdate();
        }
    }

    private deleteMapping() {
        this.setState({ showConfiguration: false}, () => {
            this.props.deleteMapping();
        });
    }

    private showTypeMappingConfiguration(show: boolean) {
        if (this.props.isBeingAutoMapped) {
            return;
        }
        if (show) {
            this.mapping = JSON.parse(JSON.stringify(this.props.mapping));
        }
        this.setState({ showConfiguration: show });
    }

    private updateMapping(updatedMapping: SmartIntDefinitionTypeMapping) {
        Object.assign(this.props.mapping, updatedMapping);
        this.setState({ showConfiguration: false });
    }

    private onConfigurationCopied(copy: SmartIntDefinitionTypeMapping): void {

    }
}
