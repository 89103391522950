import { ValueMappingsGroup } from './value-mappings-group.type';
import { ValueMapping } from './value-mapping.type';
import { PropertyValue } from './property-value.type';

export function createValueMappingsGroups(valueMappings: ValueMapping[]): ValueMappingsGroup[] {
    const groups: ValueMappingsGroup[] = [];
    for (let valueMapping of valueMappings) {
        if (groups.length === 0) {
            groups.push(createValueMappingsGroup(valueMapping));
            continue;
        }
        const group = findGroupWithSimilarValue(valueMapping, groups);
        if (!group) {
            groups.push(createValueMappingsGroup(valueMapping));
        } else {
            addToGroup(valueMapping, group);
        }
    }
    return groups;
}

function createValueMappingsGroup(valueMapping: ValueMapping): ValueMappingsGroup {
    return {
        valueMappings: [valueMapping],
        leftValue: valueMapping.left.value,
        rightValue: valueMapping.right.value,
        multipleSide: '',
        properties: []
    };
}

function findGroupWithSimilarValue(valueMapping: ValueMapping, groups: ValueMappingsGroup[]): ValueMappingsGroup | null {
    for (let group of groups) {
        if (group.leftValue === valueMapping.left.value) {
            return group;
        } else if (group.rightValue === valueMapping.right.value) {
            return group;
        }
    }
    return null;
}


function addToGroup(valueMapping: ValueMapping, group: ValueMappingsGroup) {
    if (group.leftValue === valueMapping.left.value) {
        group.rightValue = '';
        group.valueMappings.push(valueMapping);
        group.multipleSide = 'right';
    } else if (group.rightValue === valueMapping.right.value) {
        group.leftValue = '';
        group.valueMappings.push(valueMapping);
        group.multipleSide = 'left';
    }
    const isThereDefaultAlready = group.valueMappings.filter(mapping => !!mapping.isDefault).length > 0;
    if (!isThereDefaultAlready) {
        group.valueMappings[0].isDefault = true;
    }
}

export function getValueMappingForAnotherOption(side: 'left' | 'right', group: ValueMappingsGroup, propertyValue: PropertyValue): ValueMapping | undefined {
    if (side === 'left') {
        return {
            isDefault: false, left: propertyValue, right: group.valueMappings[0].right
        };
    } else {
        return {
            isDefault: false, left: group.valueMappings[0].left, right: propertyValue
        };
    }
}
