import React from 'react';
import { UI } from 'src/engrator-core';
import { AppsSupport } from '../../visual-integration-designer';
import { SmartIntTrigger } from '../../../definition/smart-int-trigger.type';
import { SmartIntDefinitionTypeMapping } from '../../../definition/smart-int-definition--type-mapping.type';
import { CITypeMappingNavBar, CITypeMappingNavBarItemName } from './ci-type-mapping-nav-bar';
import { CiPullRequestsConfiguration } from './ci-pull-requests-configuration';
import { getSmartIntsCISupport } from './ci-type-mapping-helper';
import { CISupport } from '../../../ci/smartints-ci-support';

type State = {
    currentTabItemName: CITypeMappingNavBarItemName;
};

type Props = {
    mapping: SmartIntDefinitionTypeMapping;
    appsSupport: AppsSupport;
    closeHandler: () => void;
    deleteHandler: () => void;
    doneHandler: (mapping: SmartIntDefinitionTypeMapping) => void;
    leftTrigger: SmartIntTrigger;
    rightTrigger: SmartIntTrigger;
}

export class CITypeMappingConfiguration extends React.Component<Props, State> {
    private readonly ciSupport: CISupport;

    constructor(props: Props) {
        super(props);
        this.state = { currentTabItemName: CITypeMappingNavBarItemName.PullRequests };
        this.ciSupport = getSmartIntsCISupport(
            this.props.appsSupport,
            this.props.leftTrigger,
            this.props.rightTrigger,
            this.props.mapping
        );
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        console.log(this.props, prevProps);
    }

    render() {
        return <div className={`type-mapping-configuration ci-configuration`}>
            <div className={`inner`}>
                <h1>{this.props.mapping.left.name} - {this.props.mapping.right.name}</h1>
                <CITypeMappingNavBar
                    selectedItem={this.state.currentTabItemName}
                    onChange={this.onTabItemNameChanged.bind(this)}
                />
                {this.state.currentTabItemName == CITypeMappingNavBarItemName.PullRequests &&
                <CiPullRequestsConfiguration
                    appsSupport={this.props.appsSupport}
                    mapping={this.props.mapping}
                    ciSupport={this.ciSupport}
                />}
                <UI.ButtonsBar
                    primary={<UI.Button onClick={() => this.props.doneHandler(this.props.mapping)} text={`Done`}/>}
                    secondary={[
                        <UI.Button onClick={() => this.props.closeHandler()} text={`Close`} appearance={'secondary'}/>,
                        <UI.Button className={`delete-btn`} onClick={() => this.props.deleteHandler()} text={`Delete`}
                                   appearance={'danger'}/>
                    ]}
                />
            </div>
        </div>
    }

    private onTabItemNameChanged(itemName: CITypeMappingNavBarItemName) {
        this.setState({ currentTabItemName: itemName });
    }
}
