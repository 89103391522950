import React from 'react';
import {DropdownOption, Icon20} from 'src/engrator-core/ui';
import { SmartIntTrigger } from '../../../definition/smart-int-trigger.type';
import { ResetHandler, SelectTypeBox } from './select-type-box';
import {
    createGenericItemSmartIntType,
    SMART_INT_TYPE_CONTINUOUS_INTEGRATION_ID
} from '../../../definition/smart-int-type.type';
import {UI} from "../../../../../../engrator-core";
import {SoftwareName} from "../../../../../../software";
import {TypeSelectionWizard} from "../type-selection-wizard";
import {GuideTooltip} from "../../../../../engagement";

type State = {
    selectedTypes: {
        left?: DropdownOption;
        right?: DropdownOption;
    },
    showModal: boolean;
};

type Props = {
    hasOneTypeMapping: boolean;
    triggers: {
        left: SmartIntTrigger;
        right: SmartIntTrigger;
    };
    addTypeHandler: (side: 'left' | 'right', option: DropdownOption) => void;
}

export class SelectTypes extends React.Component<Props, State> {
    private selectedOptions: { left?: DropdownOption, right?: DropdownOption } = {};
    private resetLeftBox: ResetHandler = {
        reset: () => {
        }
    };
    private resetRightBox: ResetHandler = {
        reset: () => {
        }
    };

    constructor(props: Props) {
        super(props);
        this.state = { selectedTypes: {}, showModal: false };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.hasOneTypeMapping !== prevProps.hasOneTypeMapping) {
            this.forceUpdate();
        }
    }

    render() {
        return <div>
            { !this.state.showModal && <React.Fragment>
                <UI.Button
                    className={`add-type-mapping ${ (!this.props.hasOneTypeMapping) ? 'anim-blinking-fade' : '' }`}
                    onClick={ () => this.setState({ showModal: true })}
                    icon={ <UI.Icon icon20={Icon20.CirclePlusWhite} /> }
                    text={ (!this.props.hasOneTypeMapping) ? `Start with mapping types` : `Add type mapping` }
                />
                {/*<GuideTooltip>Select the type</GuideTooltip>*/}
            </React.Fragment> }
            { this.state.showModal && <UI.Modal
                header={`Map Your Types for a Seamless Integration`}
            >
                <UI.Form>
                    <UI.Message appearance={"info"}>
                        Ensure a seamless sync by mapping issue types like 'task,' 'incident,' and 'epic' here. This guarantees accurate data alignment across your platforms.
                    </UI.Message>
                    <div className={`flex row add-type-mapping-row`}>
                        <div className={`left`}>
                            <TypeSelectionWizard
                                trigger={this.props.triggers.left}
                                optionSelected={ (option) => this.typeSelected('left', option) }
                                softwareName={this.props.triggers.left.app as SoftwareName}
                            />
                        </div>
                        <div className={`right`}>
                            <TypeSelectionWizard
                                trigger={this.props.triggers.right}
                                optionSelected={ (option) => this.typeSelected('right', option) }
                                softwareName={this.props.triggers.right.app as SoftwareName}
                            />
                        </div>
                    </div>
                    <UI.ButtonsBar
                        primary={<UI.Button
                            onClick={ () => this.addTypeHandler() }
                            text={`Add`}
                            disabled={ !this.state.selectedTypes.left || !this.state.selectedTypes.right }
                        />}
                        secondary={[
                            <UI.Button
                                onClick={ () => this.setState({ showModal: false })}
                                appearance={"secondary"}
                                text={`Close`}
                            />
                        ]}
                    />
                </UI.Form>
            </UI.Modal> }
        </div>
    }

    private typeSelected(side: 'left' | 'right', option?: DropdownOption): void {
        if (option) {
            const selectedTypes = this.state.selectedTypes;
            selectedTypes[side] = option;
            this.setState({ selectedTypes });
        }
    }

    private addTypeHandler() {
        if (this.state.selectedTypes.left && this.state.selectedTypes.right) {
            this.props.addTypeHandler('left', this.state.selectedTypes.left);
            this.props.addTypeHandler('right', this.state.selectedTypes.right);
            this.selectedOptions = {};
            this.resetLeftBox.reset();
            this.resetRightBox.reset();
            this.setState({ showModal: false });
        }
    }
}
