 import React, {Component, useEffect} from 'react';
import { CreateIntegrationGuide } from 'src/app/system/user-activity';
import {isJiraServerNativeApp, UI} from 'src/engrator-core';
import { ContextMenuItem } from 'src/engrator-core/ui';
import RightPanel from '../../../../designer/container/right-panel/RightPanel';
import { Footer } from '../../../footer';
import { ConnectionModel } from '../connection-model';
import { ConnectionDebugger } from '../debugger';
import { DeleteConnectionModal } from '../edit/delete-connection-modal';

import { IntegrationModuleTabBar } from './../../integration-module-tab-bar';
import { ConnectionEdit } from './../edit/connection-edit-component';
import { fetchAll } from './../rest-api';
import { ConnectionListItemContextMenu } from './connections-list-item-context-menu';
import './connections-list-page.css';
import {getSoftwareName} from "../../../../software";
import {useGlobalState} from "../../../global-store";
import {workflowsVerticalMenu} from "../../vertical-menu";
import {MainMenuItem} from "../../../global-store/create-global-state";
 import {SoftwareLogo} from "../../../../designer/step-creator/software-logo";
 import {OwnerTag} from "../../smartints/owner-tag";
 import {ConnectionExport} from "./import-export/connection-export";
 import {ConnectionImport} from "./import-export";
 import {getAccessOptionTagName} from "../../../../software/generic/connections-rest-api";
 import {IntegrationAccessLevel} from "../../integration-suites/details/integration-details-model";
 import {ConnectionsListActions} from "./connections-list-actions";


type ConnectionsListPageState = {
    connectionToEdit?: ConnectionModel;
    connectionToDebug?: ConnectionModel;
    connectionToDelete?: ConnectionModel;
    shouldReload: boolean;
    currentModal?: {
        name: 'export' | 'import';
        connection?: ConnectionModel;
    };
};

class ConnectionsListPageCmp extends Component<{}, ConnectionsListPageState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            shouldReload: false
        };
    }

    editConnection(connection: ConnectionModel) {
        this.setState({ connectionToEdit: undefined } , () => {
            setTimeout(() => {
                this.setState({connectionToEdit: connection});
            }, 200);
        });
    }

    onCloseClick() {
        this.setState({ connectionToEdit: undefined, connectionToDebug: undefined });
    }

    render() {
        return (
            <UI.Page className={`pipelines-page`}>
                { this.state.connectionToDelete && <DeleteConnectionModal
					connection={ this.state.connectionToDelete }
					closeHandler={ () => this.setState({ connectionToDelete: undefined }) }
                    onDeleteHandler={ () => this.onDeleteHandler() }
				/> }
                <div className={`connections-tabbar`}>
                    <UI.Message appearance={'info'}>
                        To find out more about managing, importing, exporting and other aspects related to connections, you can <UI.DocumentationLink text={`check our docs`} url={`https://docs.getint.io/getintio-platform/connections`} />.
                    </UI.Message>
                    <UI.Button
                        icon={ <UI.Icon icon={'import'} /> }
                        appearance={`secondary`}
                        onClick={() => this.setState({ currentModal: { name: 'import' }})} text={`Import`}/>
                </div>
                <div className="connections-list-page">
                    <UI.Table
                        emptyTableHolder= { <CreateIntegrationGuide /> }
                        dataSource={ fetchAll }
                        shouldReload={ this.state.shouldReload }
                        rowDecorator={(listItem: ConnectionModel) => [
                            // <UI.Button appearance="link-inline" onClick={ () => this.editConnection(listItem)} text={ `#${listItem.id}` } />,
                            <UI.Button appearance="link-inline" onClick={ () => this.editConnection(listItem)} text={ '#' + listItem.id } />,
                            <UI.Button appearance="link-inline" onClick={ () => this.editConnection(listItem)} text={ listItem.name } />,
                            <div className={`app-with-url`}><SoftwareLogo softwareName={ listItem.softwareName } /> <a target={`_blank`} href={ listItem.url}>{ listItem.url }</a> </div>,
                            <OwnerTag owner={ listItem.owner }/>,
                            <UI.Tag transparentBg={true} name={ getAccessOptionTagName(listItem.shareAccess) } />,
                            <ConnectionsListActions
                                handler={ (item) => this.contextMenuItemSelected(item, listItem)}
                                accessLevel={listItem.accessLevel} />
                        ]}
                    />

                    { this.state.connectionToEdit &&
						<ConnectionEdit
                            closeHandler={ () => this.onCloseClick()}
							connection={this.state.connectionToEdit}
						/>
					}

                    { this.state.connectionToDebug && <
						ConnectionDebugger
                            closeHandler={ () => this.onCloseClick() }
							connection={this.state.connectionToDebug}
						/> }
                    { this.state.currentModal?.name === 'export' && <ConnectionExport
                        connection={ this.state.currentModal.connection! }
                        closeHandler={ () => this.setState({ currentModal: undefined })}
                    /> }
                    { this.state.currentModal?.name === 'import' && <ConnectionImport
                        connection={ this.state.currentModal.connection! }
                        closeHandler={ (refresh) => this.onCloseImportModal(refresh) }
                    /> }
                </div>
                <Footer/>
            </UI.Page>
        )
    }

    private onCloseImportModal(refresh: boolean): void {
        if (refresh) {
            this.setState({ currentModal: undefined, shouldReload: !this.state.shouldReload });
        } else {
            this.setState({ currentModal: undefined });
        }
    }

    private contextMenuItemSelected(item: ContextMenuItem, connectionToDebug: ConnectionModel) {
        if (item.id === 'Debug') {
            this.setState({ connectionToDebug  });
        } else if (item.id === 'Delete') {
            this.setState({ connectionToDelete: connectionToDebug });
        } else if (item.id === 'Export') {
            this.setState({ currentModal: { name: 'export', connection: connectionToDebug} });
        } else if (item.id === 'Edit') {
            this.setState({ connectionToEdit: connectionToDebug });
        }
    }

    private onDeleteHandler() {
        this.setState({ shouldReload: !this.state.shouldReload });
    }
}

export function ConnectionsListPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMenu(workflowsVerticalMenu);
        setMainMenuItem(MainMenuItem.Workflows);
    }, []);
    return <ConnectionsListPageCmp />
}