import React, {Component, useEffect} from "react";

import {FieldError, UI} from 'src/engrator-core';

import { IntegrationModuleTabBar } from './../integration-module-tab-bar';
import {delerteAlert, fetchNotifications} from "./rest-api";
import {ContextMenuItem, Icon20, TableGridData} from "../../../engrator-core/ui";
import {NotificationAlert} from "./notification-alert.type";
import {NotificationsCreatePage} from "./create/notifications-create.page";
import {NotificationsListItemContextMenu} from "./notifications-list-item-context-menu";
import {useGlobalState} from "../../global-store";
import {workflowsVerticalMenu} from "../vertical-menu";
import {MainMenuItem} from "../../global-store/create-global-state";
import {getAlertChannelName} from "./create/new-alert.type";

type Props = {};
type State = {
    error?: FieldError,
    isLoading: boolean,
    showAlertModal: boolean;
};

export class NotificationsListPageCmp extends Component<Props, State> {
    private tableGridData?: TableGridData = undefined;
    constructor(props: {}) {
        super(props);
        this.state = {
            showAlertModal: false,
            isLoading: false
        };
    }

    componentDidMount(): void {
        this.loadData();
    }

    private loadData(): void {
        this.setState({ isLoading: true}, () => {
            fetchNotifications()
                .then(alerts => {
                    this.tableGridData = this.createData(alerts);
                    this.setState({ isLoading: false });
                })
                .catch(error => this.setState({ isLoading: false, error}));
        });
    }

    render() {
        return (
            <UI.Page className={`notifications-list-page`}>
                <IntegrationModuleTabBar selectedIndex={ 3 }>
                    <UI.Button
                        icon={ <UI.Icon icon20={ Icon20.CirclePlusWhite } /> }
                        className={`selenium-create-notification`}
                        onClick={() => this.showAlertModal() }
                        text={`Create Alert`}
                    />
                </IntegrationModuleTabBar>
                { this.state.error && <UI.Message appearance={"error"} message={ this.state.error?.message} /> }
                { this.state.isLoading && <UI.Loader visible={ true } appearance={"darkgray"}/> }
                { this.state.showAlertModal && <NotificationsCreatePage
                    closeHandler={ (shouldRefresh) => this.closeAlertModal(shouldRefresh) }
                /> }
                { !this.state.isLoading && this.tableGridData && <React.Fragment>
                    <UI.TableGrid
                        data={ this.tableGridData!.data }
                        headers={ this.tableGridData!.headers }
                    />
                </React.Fragment> }
            </UI.Page>
        )
    }

    private contextMenuItemSelected(item: ContextMenuItem, alert: NotificationAlert) {
        if (item.id === 'Delete') {
            delerteAlert(alert)
                .then(() => {
                    this.loadData();
                })
        }
    }

    private closeAlertModal(shouldRefresh: boolean) {
        this.setState({ showAlertModal: false });
        if (shouldRefresh) {
            this.loadData();
        }
    }

    private showAlertModal() {
        this.setState({ showAlertModal: true });
    }

    private createData(notifications: NotificationAlert[]): TableGridData {
        return {
            data: notifications.map(alert => [
                // <UI.NavLink path={`/app/integration/notifications/${alert.id}`}>#{alert.id}</UI.NavLink>,
                alert.name,
                alert.forRuns + '',
                alert.forSyncs + '',
                getAlertChannelName(alert.channel),
                <NotificationsListItemContextMenu
                    onSelected={(item) => this.contextMenuItemSelected(item, alert)}
                />
            ]),
            headers: ['Name', 'For Runs', 'For Syncs', 'Channel', 'Actions'],
        };
    }
}

export function NotificationsListPage() {
    const [, setMenu] = useGlobalState('menu');
    const [, setMainMenuItem] = useGlobalState('mainMenuItem');

    useEffect(() => {
        setMenu(workflowsVerticalMenu);
        setMainMenuItem(MainMenuItem.Workflows);
    }, []);
    return <NotificationsListPageCmp />
}