import {axiosWrapper, copyObject} from 'src/engrator-core';
import {SoftwareName} from 'src/software';
import {DropdownOption} from '../../../../engrator-core/ui';
import {PropertyDefinition} from '../../../../generic/artifacts';
import {createIntegrationDetailsModel} from '../../integration-suites/details/integration-details-model';
import {PipelineSettings} from '../../integration-suites/details/pipeline-settings-type';
import {PropertiesContext} from '../configuration';
import {SmartIntDefinition} from '../definition';
import {SmartIntTrigger} from '../definition/smart-int-trigger.type';
import {SmartIntType} from '../definition/smart-int-type.type';
import {createPropertyMapping, PropertyMapping, PropertyMappingOptions} from './property-mapping.type';
import {SmartIntDefinitionTypeMapping} from "../definition/smart-int-definition--type-mapping.type";
import {fixIntegrationModel} from "../../integration-suites/details/rest-api";

const ENDPOINT_URL = '/integration/smart-ints';
const INTEGRATION_SUITES_ENDPOINT_URL = '/integration/suites';

export type AutoBuildResult = {
    definition: SmartIntDefinition;
    summary: {
        entries: {
            message: string;
        }[]
    }
};

export function removeProblematicFieldsForJiraDC(definition: SmartIntDefinition) {
    const copiedDefinition = copyObject(definition);
    copiedDefinition.types = copiedDefinition.types.map(t => copyTypeMapping(t));
    return copiedDefinition;
}

function copyTypeMapping(typeMapping: SmartIntDefinitionTypeMapping) {
    const copiedType = copyObject(typeMapping);
    delete copiedType.direction;
    delete copiedType.commentsConfiguration.configurationType;
    delete copiedType.attachmentsConfiguration.configurationType;
    delete copiedType.statusTransitionConfiguration.configurationType;
    delete copiedType.advancedConfiguration.configurationType;
    delete copiedType.ciConfiguration?.configurationType;
    delete copiedType.hierarchyConfiguration.configurationType;
    delete copiedType.propertiesConfiguration.configurationType;
    copiedType.propertiesConfiguration.properties.forEach(p => {
        delete (p as any).valueMapping;
        delete p.isNewlyCreated;
        p.groups?.forEach(g => g.valueMappings?.forEach(v => {
            delete v.isDefault;
            delete (v as any).default;
        }));
    });
    copiedType.statusTransitionConfiguration.groups.forEach(g => g.valueMappings?.forEach(v => {
        delete v.isDefault;
        delete (v as any).default;
    }));
    delete (copiedType.statusTransitionConfiguration as any).values;
    return copiedType;
}

export async function autoBuildIntegration(definition: SmartIntDefinition): Promise<AutoBuildResult> {
    return new Promise((resolve, reject) => {
        try {
            const copiedDefinition = removeProblematicFieldsForJiraDC(definition);
            axiosWrapper.post(ENDPOINT_URL + `/auto-build`, {definition: copiedDefinition})
                .then((response: any) => {
                    resolve({
                        definition: JSON.parse(response.definition),
                        summary: response.summary
                    });
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export async function autoMapFields(leftTrigger: SmartIntTrigger, rightTrigger: SmartIntTrigger, typeMapping: SmartIntDefinitionTypeMapping): Promise<AutoBuildResult> {
    return new Promise((resolve, reject) => {
        try {
            const copiedTypeMapping = copyTypeMapping(typeMapping);
            axiosWrapper.post(ENDPOINT_URL + `/auto-build/map-fields`, {
                triggers: {
                    left: leftTrigger,
                    right: rightTrigger
                }, typeMapping: copiedTypeMapping
            })
                .then((response: any) => {
                    resolve({
                        definition: JSON.parse(response.definition),
                        summary: response.summary
                    });
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export async function autoMapOptions(triggers: { left: SmartIntTrigger, right: SmartIntTrigger }, typeMapping: SmartIntDefinitionTypeMapping, propertyMapping: PropertyMapping): Promise<AutoBuildResult> {
    return new Promise((resolve, reject) => {
        try {
            const copiedTypeMapping = copyTypeMapping(typeMapping);
            axiosWrapper.post(ENDPOINT_URL + `/auto-build/map-options`, {
                triggers,
                typeMapping: copiedTypeMapping,
                propertyMapping: createPropertyMapping(propertyMapping.left, propertyMapping.right)
            })
                .then((response: any) => {
                    resolve({
                        definition: JSON.parse(response.definition),
                        summary: response.summary
                    });
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

export const validateTrigger = (softwareName: SoftwareName, trigger: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/${softwareName}/validate-trigger`, trigger)
                .then(() => {
                    resolve();
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const saveSmartIntDefinition = (name: string, definition: SmartIntDefinition, id?: number): Promise<any> => {
    return new Promise((resolve, reject) => {
        try {
            const action: 'post' | 'put' = (id) ? 'put' : 'post';
            const url = (id) ? INTEGRATION_SUITES_ENDPOINT_URL + `/${id}` : INTEGRATION_SUITES_ENDPOINT_URL;
            axiosWrapper[action](url, {
                suiteType: 'SmartInt',
                name: name,
                configuration: definition,
                settings: {
                    workingWeekends: true,
                    status: 'Enabled',
                    threadId: 0,
                    workingHourFrom: undefined,
                    workingHourTo: undefined
                } as PipelineSettings
            })
                .then((data: any) => {
                    resolve(createIntegrationDetailsModel(data));
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchMappings = (softwareName: SoftwareName, trigger: any, kind: 'types' | 'fields'): Promise<DropdownOption[]> => {
    return new Promise((resolve, reject) => {
        try {
            fixIntegrationModel(trigger);
            axiosWrapper.post(ENDPOINT_URL + `/${softwareName}/mappings/${kind}`, trigger)
                .then((data: any) => {
                    const options: DropdownOption[] = data.map((item: any) => ({
                        value: item.id,
                        label: item.name,
                        object: {
                            value: item.id,
                            label: item.name,
                            object: item.internal
                        }
                    }));
                    resolve(options);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchPropertiesOptions = (softwareName: SoftwareName, property: PropertyDefinition, trigger: SmartIntTrigger, smartIntType: SmartIntType, propertyMappingOptions: PropertyMappingOptions): Promise<any> => {
    const triggerCopy = JSON.parse(JSON.stringify(trigger));
    if (triggerCopy.itemsFilters) {
        delete triggerCopy.itemsFilters;
    }
    return new Promise((resolve, reject) => {
        try {
            // Notion can contain some strange characters and because of this request fails with CORS error
            // lets base64 encode this property id (e.g. notion%3A%2F%2Ftasks%2Fpriority_property) so request can pass
            // on backend side, proeprty ID is not read anyway from the url, its taken from the POST body
            const propertyId = (softwareName === SoftwareName.Notion) ? btoa(property.id) : property.id;
            axiosWrapper.post(ENDPOINT_URL + `/${softwareName}/mappings/properties/${propertyId}/options`, {
                property,
                trigger: triggerCopy,
                smartIntType,
                propertyOptionsMap: property.options,
                propertyMappingOptions
            })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchProperties = (softwareName: SoftwareName, trigger: any, smartIntType: SmartIntType, context?: PropertiesContext, valueGroupOptionId?: string): Promise<PropertyDefinition[]> => {
    const triggerCopy = JSON.parse(JSON.stringify(trigger));
    if (triggerCopy.itemsFilters) {
        delete triggerCopy.itemsFilters;
    }
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/${softwareName}/mappings/properties?context=${context}`, {
                trigger: triggerCopy,
                smartIntType,
                valueGroupOptionId
            })
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchStatusFieldId = (softwareName: SoftwareName, trigger: any): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/${softwareName}/mappings/status-field-id`, {trigger})
                .then((data: any) => {
                    resolve(data);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export function fetchPropertiesNew(softwareName: SoftwareName, trigger: any, smartIntType: SmartIntType, mappings: PropertyMapping[], context?: PropertiesContext, valueGroupOptionId?: string): Promise<DropdownOption[]> {
    const triggerCopy = JSON.parse(JSON.stringify(trigger));
    if (triggerCopy.itemsFilters) {
        delete triggerCopy.itemsFilters;
    }
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + `/${softwareName}/mappings/properties?context=${context}`, {
                trigger: triggerCopy,
                smartIntType,
                valueGroupOptionId
            })
                .then((data: any) => {
                    const options: DropdownOption[] = data.map((option: any) => ({
                        value: option.id,
                        label: option.name,
                        object: option
                    }));
                    putIdForExistingProperties(options);
                    resolve(options);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
}

function putIdForExistingProperties(options: DropdownOption[]): void {
    const names = options.map(o => o.label);
    for (const option of options) {
        let counter = 0;
        // count how many options are with the same name
        for (const name of names) {
            if (name === option.label) {
                counter++;
            }
        }
        // If more than 1, add to name of the option the ID
        if (counter > 1) {
            option.label = option.label + ` (${option.value}) `;
        }
    }
}