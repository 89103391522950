import { axiosWrapper } from 'src/engrator-core';
import { ConnectionModel, ConnectionUpdateData } from './connection-model';
import { createConnectionListItem } from './list/connection-list-item';

export type DebugResult = {
    data?: string;
    error?: string;
};

const ENDPOINT_URL = '/integration/connections';

export function fetchAll(): Promise<any> {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL)
            .then((data: any) => {
                resolve({
                    headers: ['ID' , 'Name', 'App & Url', 'Owner', 'Permissions', 'Actions'],
                    data: data.map(createConnectionListItem)
                });
            })
            .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const fetchConnection = (connectionId: number): Promise<ConnectionModel> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.get(ENDPOINT_URL + '/' + connectionId + '/details')
                .then((data: any) => {
                    const connection = createConnectionListItem(data);
                    resolve(connection);
                })
                .catch(error => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const updateConnection = (id: number, connectionUpdateData: ConnectionUpdateData): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.put(ENDPOINT_URL + '/' + id, connectionUpdateData)
            .then((data: any) => {
                resolve();
            })
            .catch((error: any) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const testConnection = (id: number, connectionUpdateData: ConnectionUpdateData): Promise<void> => {
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + '/' + id + '/test', connectionUpdateData)
            .then((data: any) => {
                resolve();
            })
            .catch((error: any) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};

export const debugEndpoint = (id: number, endpointUrl: string): Promise<DebugResult> => {
    // return Promise.resolve({data: '{"widget": { "debug": "on", "window": { "title": "Sample Konfabulator Widget", "name": "main_window", "width": 500, "height": 500 }, "image": { "src": "Images/Sun.png", "name": "sun1", "hOffset": 250, "vOffset": 250, "alignment": "center" }, "text": { "data": "Click Here", "size": 36, "style": "bold", "name": "text1", "hOffset": 250, "vOffset": 100, "alignment": "center", "onMouseUp": "sun1.opacity = (sun1.opacity / 100) * 90;" } }}'});
    return new Promise((resolve, reject) => {
        try {
            axiosWrapper.post(ENDPOINT_URL + '/' + id + '/debug', {endpointUrl})
            .then((data: any) => {
                resolve(data);
            })
            .catch((error: any) => reject(error));
        } catch (error) {
            reject(error);
        }
    });
};
