import React, { Component } from "react";
import { UI } from 'src/engrator-core';

import { fetchAll } from './rest-api';
import { IntegrationModuleTabBar } from './../../integration-module-tab-bar';
import { IntegrationListItem, LastRun } from "./integration-list-item";

function LastRunShortSummary(props: {runDetails: LastRun}) {
    if (!props.runDetails) {
        return <div>-</div>;
    }
    return <div className={`status`}>
        <UI.ExecutionStatus status={ props.runDetails.status } />
        <UI.ExecutionTime startTime={ props.runDetails.formattedStartTime } formattedExecutionTime={ props.runDetails.formattedExecutionTime} />
    </div>
}
export class IntegrationsListPage extends Component {
    render() {
        return (
            <UI.Page className={`pipelines-page`}>
                <IntegrationModuleTabBar selectedIndex={ 1 }>
                    <UI.NavLink path="/app/integration/create"><UI.Button onClick={() => {}} text={`Create Workflow`} /></UI.NavLink>
                </IntegrationModuleTabBar>
                <UI.Table
                    dataSource={ fetchAll }
                    rowDecorator={(listItem: IntegrationListItem) => [
                        <UI.NavLink path={`/app/integration/pipelines/${listItem.id}/edit`}>{ listItem.name }</UI.NavLink>,
                        <LastRunShortSummary runDetails={ listItem.lastRun } />,
                        <LastRunShortSummary runDetails={ listItem.lastSuccessfulRun } />,
                        (listItem.status) ? <UI.EntityStatus status={listItem.status} /> : null
                    ]}
                />
            </UI.Page>
        )
    }
}
