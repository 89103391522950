import {VerticalMenu} from "src/app/global-store/create-global-state";

export const jiraServerSettingsVerticalMenu: VerticalMenu = {
    header: 'Settings',
    items: [
        // {
        // name: 'General',
        // path: '/app/jira-server/settings/general'
    // },
        {
        name: 'Dedicated license',
        path: '/app/jira-server/settings/migration-license'
    }, {
        name: 'Data Storage',
        path: '/app/jira-server/settings/data-storage'
    }]
};