import {SoftwareName} from "../../../../../software";
import React from "react";
import softwareFactory from "../../../../../software/software-factory";
import {Connection} from "../../../../../connections";
import {DropdownOption} from "../../../../../engrator-core/ui";
import {UI} from "../../../../../engrator-core";

type State = {
    error?: any;
    dropdownOptions: DropdownOption[];
    isLoading: boolean;
    selectedConnectionId?: number;
};

type Props = {
    changeHandler: (connectionId: number) => void;
    softwareName: SoftwareName;
}

export class ChangeConnection extends React.Component<Props, State> {
    private connectionFactory: any;
    constructor(props: Props) {
        super(props);
        this.state = {
            dropdownOptions: [],
            isLoading: false,
        };
        this.connectionFactory = softwareFactory.getSoftwareByName(this.props.softwareName)?.getConnectionFactory();
    }

    async componentDidMount() {
        await this.setState({ isLoading :true });
        this.connectionFactory.fetchConnections()
            .then((connectionsList: any) => {
                const dropdownOptions: DropdownOption[] = connectionsList.map((connection: Connection) => ({
                    value: connection.id,
                    label: connection.name
                }));
                this.setState({
                    isLoading: false,
                    dropdownOptions: dropdownOptions
                });
            })
            .catch((error: any) => this.setState({ isLoading: false, error }))
    }

    render() {
        return <div className={`connection-change`}>
            { this.state.isLoading && <UI.Loader visible={true} appearance={'darkgray'} /> }
            { !this.state.isLoading && <UI.Dropdown
                options={ this.state.dropdownOptions }
                onChange={(connectionId: number) => this.setConnectionId(connectionId)}
            /> }
            {this.state.selectedConnectionId && <UI.Message
                appearance={'warning'}>Changing the connection may cause data sync issues. Ensure new user has enough permissions and access level to configured types and fields.</UI.Message> }
            { this.state.selectedConnectionId && <UI.Button
                disabled={ !this.state.selectedConnectionId }
                onClick={ () => this.applyClicked() }
                text={`Change connection`}
            /> }
        </div>
    }

    private setConnectionId(connectionId: number) {
        this.setState({ selectedConnectionId: connectionId });
    }

    private applyClicked(): void {
        if (this.state.selectedConnectionId) {
            this.props.changeHandler(this.state.selectedConnectionId);
        }
    }
}