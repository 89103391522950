import {ValueMappingsGroup} from "../../visual/components/value-mappings/value-mappings-group.type";

let optionsCopy: ValueMappingsGroup[] = [];

export function copyMappedOptions(groups: ValueMappingsGroup[]): void {
    optionsCopy = JSON.parse(JSON.stringify(groups));
}

export function areSomeMappingsCopied(): boolean {
    return optionsCopy.length > 0;
}

export function reuseCopiedOptions(): ValueMappingsGroup[] {
    return optionsCopy;
}
