import {FieldError} from "./field-error";

export const extractErrorFromResponse = (errorFromResponse: any): FieldError => {
    console.error(`error from response`, errorFromResponse);
    console.error(`error from response`, errorFromResponse.message);
    console.error(`error from response`, errorFromResponse.response);
    console.error(`error from response`, errorFromResponse.response?.data);
    if (errorFromResponse.response && errorFromResponse.response.status === 400) {
        const errorData = errorFromResponse.response.data;
        if (typeof errorData === 'string') {
            return {
                fieldName: 'general',
                message: errorData
            };
        }
        return errorData;
    }
    let errorMessage = errorFromResponse.message;
    if (errorMessage && errorMessage === 'Network Error') {
        errorMessage = 'Network Error. You are offline or Getint platform is not accessible.';
    }
    const message = [
        (errorMessage) ? errorMessage : 'Unknown error occured'
    ];
    if (errorFromResponse.response && errorFromResponse.response.data) {
        if (errorFromResponse.response.data.error) {
            message.push('Error: ' + errorFromResponse.response.data.error);
        } else {
            message.push('Error: ' + errorFromResponse.response.data);
        }
    }
    return {
        fieldName: 'general',
        message: message.join('. ')
    };
};