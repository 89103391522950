import React from 'react';
import { UI } from 'src/engrator-core';
import {IntegrationListItem} from "../integration-list-item";
import { importScript, ImportValidateResult, ScriptToImport, validateImportedScript } from './rest-api';
import {FormError} from "../../../../../../engrator-core/form-error.type";
import {IntegrationImportConnections} from "./integration-import-conncetions";
import {rejects} from "assert";

type Props = {
    closeHandler: () => void;
};

type State = {
    isLoading: boolean;
    stepIndex: number;
    script?: string;
    error?: FormError;
    validateResult?: ImportValidateResult;
    scriptToImport: ScriptToImport;
    createdIntegrationId?: number;
};

export class IntegrationImport extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            stepIndex: 1,
            scriptToImport: {
                script: '',
                name: '',
                rightConnectionId: 0,
                leftConnectionId: 0
            }
        };
    }

    render() {
        let isDisabled = false;
        let btnText = '';
        const showPrimaryBtn = (!this.state.createdIntegrationId);
        if (this.state.stepIndex === 1) {
            isDisabled = !this.state.script || this.state.isLoading;
            btnText = 'Next';
        } else if (this.state.stepIndex === 2) {
            isDisabled =  this.state.isLoading || !this.state.scriptToImport.name || !this.state.scriptToImport.script || !this.state.scriptToImport.leftConnectionId || !this.state.scriptToImport.rightConnectionId;
            btnText = 'Import';
        }
        return <UI.FullScreenModal
            closeBtnHandler={ () => this.closeClicked() }
            primaryBtnHandler={ () => this.primaryClicked() }
            primaryBtnText={ btnText }
            showPrimaryBtn={ showPrimaryBtn }
            isPrimaryDisabled={ isDisabled }
            header={`Import integration`}
        >
            { this.state.error && <UI.Message appearance={'error'}>
                    <p>Something went wrong: { this.state.error.message }</p>
            </UI.Message> }
            { !this.state.validateResult && <React.Fragment>
                <p>Please paste internal script</p>
                <UI.Textarea onChange={ (script) => this.setState({ script }) }  rows={ 20 } />
            </React.Fragment> }
            { this.state.validateResult && !this.state.createdIntegrationId && <UI.Form>
                <UI.FormGroup
					label={`Provide integration name`}
                    isRequired={ true }
                >
                    <UI.Input
                        onChange={ this.integrationNameChanged.bind(this) }
                    />
				</UI.FormGroup>
                <IntegrationImportConnections
                    validationResult={ this.state.validateResult! }
					connectionIdSelectedHandler={ this.connectionIdSelectedHandler.bind(this) }
                />
            </UI.Form> }
            { this.state.createdIntegrationId && <UI.Message appearance={"info"}>
                <p>Your integration has been imported successfully!</p>
                <p>Please notice, that integration is <b>DISABLED</b> at the moment. You can enable it in Settings section.</p>
				<UI.NavLink path={`/app/integration/smart-ints/${ this.state.createdIntegrationId }/edit`}>Go to integration</UI.NavLink>
            </UI.Message> }
        </UI.FullScreenModal>
    }

    private primaryClicked(): Promise<boolean> {
        if (this.state.stepIndex === 1) {
            return this.validateImport();
        }
        return this.importClicked();
    }


    private validateImport(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.setState({isLoading: true}, () => {
                validateImportedScript(this.state.script!)
                    .then(result => {
                        const scriptToImport = this.state.scriptToImport;
                        scriptToImport.script = JSON.parse(this.state.script!);
                        this.setState({stepIndex: 2, error: undefined, isLoading: false, scriptToImport, validateResult: result});
                        resolve(false);
                    }).catch(error => {
                    this.setState({isLoading: false, error})
                    resolve(false);
                });
            });
        });
    }

    private importClicked(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.setState({error: undefined, isLoading: true}, () => {
                importScript(this.state.scriptToImport)
                    .then((createdIntegrationId) => {
                        this.setState({isLoading: false, createdIntegrationId})
                        resolve(false);
                    }).catch(error => {
                    this.setState({isLoading: false, error})
                    resolve(false);
                });
            });
        });
    }

    private connectionIdSelectedHandler(side: 'left' | 'right', connectionId: number) {
        const scriptToImport = this.state.scriptToImport;
        if (side === 'left') {
            scriptToImport.leftConnectionId = connectionId;
        }
        if (side === 'right') {
            scriptToImport.rightConnectionId = connectionId;
        }
        this.setState({ scriptToImport });
    }

    private integrationNameChanged(newValue: string) {
        const scriptToImport = this.state.scriptToImport;
        scriptToImport.name = newValue;
        this.setState({ scriptToImport });
    }

    private closeClicked() {
        this.props.closeHandler();
    }
}
