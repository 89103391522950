import React, { ChangeEvent } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { UI } from 'src/engrator-core';
import { ArtifactBuilderScene, DataArtifactBuilder } from './generic/artifact-builder';
import { ArtifactDirection, ArtifactsFactory, DataArtifact, PropertyDefinition } from './generic/artifacts';
import { Artifacts } from './generic';
import { ConfigurationComponent, StepArtifact, StepType } from './generic/software/step';
import PropertyMappingCreator from './generic/artifact-builder/PropertyMappingCreator';
import PropertyDataType from './generic/artifacts/property-data-type';
import RightPanel from './designer/container/right-panel/RightPanel';
import { ConfiguredProperty } from './generic/artifacts/data-artifact';
import { DropdownFilter, InputFilter, NumberRangeFilter } from './app/reporting/filters';
import { FilterChangeHandler } from './app/reporting/filters/filter-change-handler.type';
import { createGenericConnectionFactory } from './generic/connections';
import { SoftwareName } from './software';
import { ConnectionFactory } from './connections';
import { AuthMethod } from './generic/connections/auth-method.type';
import { PipelineStepRules } from './generic/pipeline-step/rules';
import PropertyMapping from './generic/artifact-builder/PropertyMapping';
import { PropertySourceType } from './generic/artifacts/property-source/property-source-type';
import { CreateCommentsStep } from './software/generic/create-comments-step/create-comments-step';
import { StatusTransition } from './software/generic/status-transition';
import { JiraStepKey } from './software/jira/steps/jira-step-key.type';
import { InputChangeListener } from './engrator-core/ui';
import { AppsSupport } from './app/integration/smartints/visual/visual-integration-designer';
import { DefaultMappingPicker } from './app/integration/smartints/visual/components/value-mappings/default-mapping-picker';
import { DefaultMappingMode } from './app/integration/smartints/visual/components/value-mappings/default-mappping.type';
import { createValueMappingsGroups } from './app/integration/smartints/visual/components/value-mappings/value-mappings-group-factory';
import { ValueMappingsGroupContainer } from './app/integration/smartints/visual/components/value-mappings/value-mappings-group-container';
import {getEmptyAdditionalData} from "./app/integration/smartints/visual/components/value-mappings";


export class DevApp extends React.Component<any, any> {
    private outDataArtifact?: DataArtifactBuilder;
    private intDataArtifact?: DataArtifactBuilder;
    private createIssueComponent?: ConfigurationComponent;
    private availableArtifacts?: any[];
    private artifactsFactory?: ArtifactsFactory;
    private connectionFactory: ConnectionFactory;
    private commentsArtifacts: StepArtifact[] = [];
    private inputListener: InputChangeListener;
    private appsSupport: AppsSupport;
    private valueMapping: PropertyMapping;

    constructor(props: any) {
        super(props);
        this.connectionFactory = createGenericConnectionFactory(SoftwareName.Trello, [AuthMethod.BasicAuth, AuthMethod.OAuth]);
        this.commentsArtifacts = [new StepArtifact('name', ArtifactDirection.Out, [], SoftwareName.Jira, {data: {}}, 0)];
        let inputListener: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
        this.inputListener = {
            subscribe: ((listener: (value: string, event: ChangeEvent<HTMLInputElement>) => void) => {
                inputListener = listener;
            }),
            notify: (newValue: string, event: ChangeEvent<HTMLInputElement>) => {
                inputListener(newValue, event);
            }
        }
        this.appsSupport = {} as AppsSupport;
        this.valueMapping = {} as PropertyMapping;
    }

    componentDidMount(): void {
        const someArtifact = {
            name: 'someArtifact',
            properties: [{
                property: {
                    id: 'summary',
                    name: 'summary',
                    dataType: PropertyDataType.String
                }
            }]
        };
        const someOtherArtifact = {
            name: 'someOtherArtifact',
            properties: [{
                property: {
                    id: 'summary',
                    name: 'summary',
                    dataType: PropertyDataType.String
                }
            }]
        };
        this.artifactsFactory = {
            getAvailableArtifacts: () => [
                someArtifact, someOtherArtifact
            ],
            getAvailableArtifactsWithProperties: (configuredProperties: ConfiguredProperty[]) => [{
                id: 'someArtifact',
                propertyDefinitions: [{
                    property: {
                        id: 'summary',
                        name: 'summary',
                        dataType: PropertyDataType.String
                    }
                }, {property: {
                        id: 'issueKey',
                        name: 'Issue Key',
                        dataType: PropertyDataType.String,
                        options: {
                            selectable: true
                        }
                    }}]
            }]
        } as unknown as ArtifactsFactory;
        //new ArtifactsFactory(0);
        this.outDataArtifact = new DataArtifactBuilder(
            ArtifactDirection.Out,
            new MyArtifact(ArtifactDirection.Out, ''),
            this.artifactsFactory as unknown as ArtifactsFactory,
            (props, builder) => {
                console.log('Out', props, builder);
            }
        );
        this.intDataArtifact = new DataArtifactBuilder(
            ArtifactDirection.In,
            new MyArtifact(ArtifactDirection.In, ''),
            this.artifactsFactory  as unknown as ArtifactsFactory,
            (props, builder) => {
                console.log('In', props, builder);
            }
        );
        this.availableArtifacts = this.outDataArtifact.getAvailableArtifactsWithProperties();
        this.forceUpdate();
    }
    render() {
        const left = [{ label: 'Label', value: 'value' }];
        const right = [{ label: 'Label', value: 'value' }];
        const mapping = { value: 'value', label: 'Label', mode: DefaultMappingMode.Value };
        const ConnectionComponent = this.connectionFactory.getConnectionComponent(() => {
        });
        const valueMappings = [
            {
                left: {
                    name: 'l_1', value: 'l_1', additionalData: getEmptyAdditionalData()
                },
                right: {
                    name: 'r_1', value: 'r_1', additionalData: getEmptyAdditionalData()
                },
                isDefault: false
            },
            {
                left: {
                    name: 'l_2', value: 'l_2', additionalData: getEmptyAdditionalData()
                },
                right: {
                    name: 'r_1', value: 'r_1', additionalData: getEmptyAdditionalData()
                },
                isDefault: false
            },
            {
                left: {
                    name: 'l_3', value: 'l_3', additionalData: getEmptyAdditionalData()
                },
                right: {
                    name: 'r_2', value: 'r_2', additionalData: getEmptyAdditionalData()
                },
                isDefault: false
            },
            {
                left: {
                    name: 'l_4', value: 'l_4', additionalData: getEmptyAdditionalData()
                },
                right: {
                    name: 'r_3', value: 'r_3', additionalData: getEmptyAdditionalData()
                },
                isDefault: false
            },
            {
                left: {
                    name: 'l_4', value: 'l_4', additionalData: getEmptyAdditionalData()
                },
                right: {
                    name: 'r_4', value: 'r_4', additionalData: getEmptyAdditionalData()
                },
                isDefault: false
            },
        ];
        const groups = createValueMappingsGroups(valueMappings);
        return <div style={{ width: '800px', background: '#fff', padding: '1rem', display: 'block', margin: '1rem' }}>
            <DefaultMappingPicker
                options={left}
                mapping={mapping}
                onChangeHandler={() => console.log(mapping)}
            />

            {groups.map((group, index) => <ValueMappingsGroupContainer
                addMultipleToSide={'left'}
                addPropertyValue={() => {
                }}
                key={index}
                group={group}
                onDeleteHandler={() => {
                }}/>)}

            {/*<ValuesMappingsPicker */}
            {/*    valueMappings={ [] } */}
            {/*    onMappingsChangedHandler={ () => {} } */}
            {/*    appsSupport={ this.appsSupport } */}
            {/*    mapping={ this.valueMapping } */}
            {/*    leftTrigger={} */}
            {/*    rightTrigger={}*/}
            {/*    closeHandler={} */}
            {/*    leftType={} */}
            {/*    rightType={}*/}
            {/*/>*/}
            <UI.Input onChange={ (value, event) => this.inputListener.notify(value, event as ChangeEvent<HTMLInputElement>) } />
            <UI.ArtifactsAutocomplete
                availableArtifacts={ [] }
                listener={ this.inputListener }
            />
            <BrowserRouter>
            <UI.Checkbox
                label={`Set this field only if`}
                checkedValue={`true`}
                uncheckedValue={`false`}
                onChange={() => {}}
            />
                <UI.FormSection
                    label={`Configure InArtifact`}
                >
                    <UI.FormGroup
                        label={ <span>In-Artifact <UI.ShortExplanation description={`Read more`} /></span>}
                        description={ <span>Artifacts are the way to transfer data. Read more aboute <UI.DocumentationLink text={`Artifacts`} url={``} />.</span>}
                    >

                    </UI.FormGroup>

                </UI.FormSection>
                { this.availableArtifacts && <React.Fragment>
                    <ArtifactBuilderScene
                        stepConfigurationFetcher={ () => {return {}; } }
                        connectionId={1} dataArtifactBuilder={ this.intDataArtifact! }  softwareName={ SoftwareName.Jira }/>
                    <RightPanel>
                       {/*<IntegrationPipelineSettings settings={{*/}
                       {/*    status: 'Enabled',*/}
                       {/*    workingHourFrom: 0,*/}
                       {/*    workingHourTo: 0,*/}
                       {/*    isWorkingWeekends: true,*/}
                       {/*    threadId: 0*/}
                       {/*}}*/}
                       {/* persistSettingsHandler={ () => {}}*/}
                       {/*closeHandler={ () => {} }/>*/}
                        <CreateCommentsStep availableArtifacts={ this.commentsArtifacts } onConfigurationChanged={() => {}}/>
                       {/*<Condition*/}
                       {/*    onConditionChanged={ (condition) => console.log(condition) }*/}
                       {/*/>*/}
                       <PipelineStepRules onRulesChanged={ () => {} }/>
                    </RightPanel>
                <h1>Dev App <UI.ShortExplanation description={`Some description to show when mouseover questionmark`}/></h1>
                <h2>Property Mapping</h2>
                <PropertyMappingCreator
                    configuredProperties={[]}
                    connectionId={ 1 }
                    stepConfigurationFetcher={ () => {}}
                    softwareName={ SoftwareName.Jira }
                    propertiesToMap={Promise.resolve([{id: 'listId', name: 'List', options: {selectable: true, internalType: ''}, dataType: PropertyDataType.String}])}
                    availableArtifacts={this.availableArtifacts}
                    addPropertyMapping={ (propertyMapping: any) => console.log(propertyMapping) }
                    direction={ ArtifactDirection.In } />
                    <PropertyMapping configuredProperty={{
                        source: {
                            condition: 'true = true',
                            sourceType: PropertySourceType.Predefined
                        },
                        property: {
                            id: 'name',
                            dataType: PropertyDataType.String,
                            name: 'Name'
                        }
                    }} deleteHandler={() => {}} />
                </React.Fragment>}
                <h2>Filters</h2>
                <div>
                    <InputFilter defaultValue={``} placeholder={``} title={``} filterChangeHandler={ this.getFilterChangeHandler('suiteName')} />
                    <NumberRangeFilter title={'Some title'} filterChangeHandler={ this.getFilterChangeHandler('executionTime')} />
                    <DropdownFilter defaultValues={``} title={"Some title"} filterChangeHandler={ this.getFilterChangeHandler('Status')} />
                </div>
                {/*<TrelloBoardListPicker isListRequired={ false } connectionId={ 16 } onDataChanged={ data =>  {} }/>*/}
                {/*<SmartintsWizard onPipelinesBuildUp={ () => {}}/>*/}
                { ConnectionComponent }
                <StatusTransition
                    stepSpecification={ {
                        action: "Change Issue Status",
                        description: "Move Jira Issue from one status to another",
                        key: JiraStepKey.JiraStatusTransition,
                        software: SoftwareName.Jira,
                        stepType: StepType.Action
                    } }
                    connectionId={1}
                    onConfigurationChanged={ (configuration) => console.log(configuration)}
                    artifactsFactory={ this.artifactsFactory! }
                    softwareName={ SoftwareName.Jira }
                    availableArtifacts={ this.commentsArtifacts }/>
            </BrowserRouter>
        </div>;
    }

    private getFilterChangeHandler(filterName: string): FilterChangeHandler {
        return filterValue => {
            console.log('filter changed', filterName, filterValue);
        };
    }
}

class MyArtifact extends DataArtifact {
    getPreferredName(): string {
        return 'jiraBug';
    }

    getPropertiesDefinitions(): Promise<Array<PropertyDefinition>> {
        return Promise.resolve([
            { id: 'summary', name: 'Summary', dataType: Artifacts.PropertyDataType.String},
            { id: 'description', name: 'Description', dataType: Artifacts.PropertyDataType.String },
            { id: 'workratio', name: 'Work Ratio', dataType: Artifacts.PropertyDataType.Integer},
            { id: 'storyPoints', name: 'Story Points', dataType: Artifacts.PropertyDataType.Integer},
        ]);
    }

}
