import {
    SmartIntAdvancedConfiguration,
    SmartIntAttachmentsConfiguration,
    SmartIntCIConfiguration,
    SmartIntCommentsConfiguration,
    SmartIntHierarchyConfiguration,
    SmartIntPropertiesMappingsConfiguration, SmartIntRelationshipConfiguration,
    SmartIntStatusTransitionConfiguration
} from './smart-int-definition-configuration.type';
import { SmartIntDirection } from './smart-int-direction.type';
import { SMART_INT_TYPE_CONTINUOUS_INTEGRATION_ID, SmartIntType } from './smart-int-type.type';

export type SmartIntDefinitionTypeMapping = {
    left: SmartIntType;
    right: SmartIntType;
    direction: SmartIntDirection;
    propertiesConfiguration: SmartIntPropertiesMappingsConfiguration;
    commentsConfiguration: SmartIntCommentsConfiguration;
    attachmentsConfiguration: SmartIntAttachmentsConfiguration;
    advancedConfiguration: SmartIntAdvancedConfiguration;
    hierarchyConfiguration: SmartIntHierarchyConfiguration;
    statusTransitionConfiguration: SmartIntStatusTransitionConfiguration;
    ciConfiguration?: SmartIntCIConfiguration;
    status?: 'Enabled' | 'Disabled'
};

export function isCISmartIntTypeMapping(typeMapping: SmartIntDefinitionTypeMapping): boolean {
    return typeMapping.left.id === SMART_INT_TYPE_CONTINUOUS_INTEGRATION_ID ||
        typeMapping.right.id === SMART_INT_TYPE_CONTINUOUS_INTEGRATION_ID;
}


export function createTypeMappingConfiguration(typeMapping: SmartIntDefinitionTypeMapping): SmartIntDefinitionTypeMapping {
    const cloned = JSON.parse(JSON.stringify(typeMapping)) as SmartIntDefinitionTypeMapping;
    return cloned;
}
